import request from '@/utils/request'

/**
 * 获取礼包列表
 * @param params
 * @returns {Promise<any>}
 */
export function getGiftGoodsList(params) {
  return request({
    url: 'seller/shopCombo/comboListTh',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 礼包禁用/启用
 * @param params
 * @returns {Promise<any>}
 */
export function changeGiftGoodsStatus(id,status) {
  return request({
    url: `seller/shopCombo/updataStatusTh/${id}/${status}`,
    method: 'post',
    loading: true,
  })
}


/**
 * 礼包删除
 * @param params
 * @returns {Promise<any>}
 */
export function delGiftGoods(id) {
  return request({
    url: `seller/shopCombo/delTh/${id}`,
    method: 'DELETE',
    loading: true,
  })
}

/**
 * 礼包备注
 * @param params
 * @returns {Promise<any>}
 */
export function editGiftRemark(comboId,remark) {
  return request({
    url: `seller/shopCombo/editRemarkTh/${comboId}`,
    method: 'post',
    loading: true,
    params:{
      remark
    }
  })
}



/**
 * 复制礼包
 * @param params
 * @returns {Promise<any>}
 */
export function copyCombo(params) {
  const { id,...data } = params;
  return request({
    url: `/seller/shopCombo/copyCombo/${id}`,
    method: 'post',
    loading: true,
    data,
    headers: { "Content-Type": "application/json" },
  })
}
/**
 * 修改礼包列表礼包前端名称
 * @param {*} params 
 * @returns 
 */
export function editCombEditName (params) {
  return request({
    url: `/seller/shopCombo/editCombEditName`,
    method: "post",
    params
  })
}