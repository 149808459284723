<template>
  <el-dialog custom-class="goodsclassify" :visible.sync="showDialog" :close-on-click-modal="false"
    :close-on-press-escape="false" append-to-body width="610px" @close="$emit('close')">
    <div slot="title" class="goods-picker-title">
      <span></span>
      批量设置自提时间
    </div>
    <el-form :model="baseInfoForm" status-icon :rules="baseInfoFormRule" label-position="right" ref="baseInfoForm"
      label-width="130px" class="demo-ruleForm">
      <el-form-item label="自提时间设置" prop="catering" style="text-align: left;">
        <el-radio-group v-model="baseInfoForm.type">
          <el-radio :label="1">指定日期自提</el-radio>
          <el-radio :label="2">下单后指定时间段自提</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="baseInfoForm.type == 1">
        <el-form-item label="自提时间" style="width: 98%;text-align: left;">
          <el-alert :closable="false" title="指定日期自提：不同时间下单购买的订单，需在同一时间段内进行自提。" type="warning">
          </el-alert>
        </el-form-item>
        <el-form-item label="自提日期" prop="starting_date" style="width: 40%;text-align: left;">
          <el-date-picker :picker-options="timeOptions" style="width: 220px" value-format="timestamp"
            v-model="order_time_range" @change="datatimeChange" type="daterange" align="center" size="medium"
            :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="自提时间段" prop="time_slot" style="width: 40%;text-align: left;">
          <el-time-picker is-range value-format="H:mm" v-model="baseInfoForm.time_slot" range-separator="-"
            start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="下单截止时间设置" prop="catering" style="text-align: left;">
          <el-radio-group v-model="deadline" @input="deadlineChange">
            <el-radio :label="1">不限</el-radio>
            <el-radio :label="2">指定日期</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="deadline == 2" label="截止日期" prop="order_deadline_time" style="width: 40%;text-align: left;">
          <el-date-picker :picker-options="timeOptions" style="width: 220px" format="yyyy-MM-dd HH:mm"
            value-format="timestamp" v-model="baseInfoForm.order_deadline_time" type="datetime" align="center"
            size="medium" :editable="false" range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
      </div>
      <div v-if="baseInfoForm.type == 2">
        <el-form-item label="" prop="catering" style="width: 70%;text-align: left;">
          <el-alert :closable="false" title="下单后指定时间段自提：下单后，需在【下单时间+备货时间】后的指定天数内、指定时间范围内进行自提。" type="warning">
          </el-alert>
        </el-form-item>
        <el-form-item label="备货时间" prop="lead_time" style="width: 80%;text-align: left;">
          <el-input-number :min="1" v-model.trim="baseInfoForm.lead_time" step-strictly></el-input-number>
        </el-form-item>
        <el-form-item label="自提天数范围" prop="pickup_days" style="width: 40%;text-align: left;">
          <div style="display: inline-block;">
            <el-input-number :min="1" v-model.trim="baseInfoForm.pickup_days" step-strictly></el-input-number>
          </div>
          <span style="padding-left: 12px;">天内</span>
        </el-form-item>
        <el-form-item label="自提时间段" prop="time_slot" style="width: 40%;text-align: left;">
          <el-time-picker :editable="false" is-range value-format="H:mm" v-model="baseInfoForm.time_slot"
            range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
          </el-time-picker>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="text-picker-footer">
      <el-button size="small" @click="$emit('close')">关 闭</el-button>
      <el-button size="small" type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { Foundation } from "@/../ui-utils";
import * as API_goods from "@/api/goods";
import { param } from '../../../../../manager-seller/src/utils';
export default {
  name: 'settingTimes',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    goodsIds: {
      type: Array,
      default: () => []
    },

  },
  data () {
    return {
      showDialog: false,
      deadline: 1,//是否有截止日期
      timeOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
        }
      },
      order_time_range: '',//日期选择器
      /** 商品详情信息提交表单 */
      baseInfoForm: {
        delivery_method: 2,//自提
        type: 1,//自提时间设置
        starting_date: '',//开始日期
        ending_date: '',//结束日期
        time_slot: '',//自提时间段
        lead_time: '',//备货时间
        pickup_days: '',//自提天数范围
        order_deadline_time: '',
        /** 是否上架，1上架 0下架 */
        market_enable: 1,
        self_operated: 1,
      },
      /** 商品详情的校验规则 */
      baseInfoFormRule: {
        starting_date: [
          { required: true, message: "请选择自提日期", trigger: "blur" }
        ],
        time_slot: [
          { required: true, message: "请选择自提时间段", trigger: "blur" }
        ],
        order_deadline_time: [
          { required: true, message: "请选择截止如期", trigger: "blur" },
          { required: true, validator: this.verificationTime, trigger: "blur" }
        ],
        lead_time: [
          { required: true, message: "请填写备货时间", trigger: "blur" }
        ],
        pickup_days: [
          { required: true, message: "请选择自提天数范围", trigger: "blur" }
        ],
        ship_regions: [
          { required: true, message: "请填写配送区域", trigger: "blur" },
        ],
      },
    }
  },
  watch: {
    show (newValue, oldValue) {
      this.reset();
      this.showDialog = newValue
    },
  },
  methods: {
    verificationTime (rule, value, callback) {
      if (this.order_time_range && this.baseInfoForm.time_slot) {
        let endtime = Foundation.unixToDate(this.order_time_range[1] / 1000);
        let time1 = endtime.split(' ')[0]
        let datatimes = this.baseInfoForm.time_slot[1];
        let newtime = time1 + ' ' + datatimes;
        if (value / 1000 > Foundation.dateToUnix(newtime)) {
          callback(new Error("截止日期不能超出最晚的自提日期"));
        }
      }
      callback();
    },
    reset () {
      this.order_time_range = ''//日期选择器
      this.baseInfoForm = {
        delivery_method: 2,//自提
        type: 1,//自提时间设置
        starting_date: '',//开始日期
        ending_date: '',//结束日期
        time_slot: '',//自提时间段
        lead_time: '',//备货时间
        pickup_days: '',//自提天数范围

        /** 是否上架，1上架 0下架 */
        market_enable: 1,
        self_operated: 1,
      }
    },
    handleSubmit () {
      this.$refs["baseInfoForm"].validate((valid) => {
        if (valid) {
          let _params = JSON.parse(JSON.stringify(this.baseInfoForm));
          _params.time_slot = `${_params.time_slot[0]}_${_params.time_slot[1]}`
          if (_params.type == 1) {//指定时间自提
            const { type, starting_date, ending_date, time_slot } = _params;
            const obj = { type, starting_date, ending_date, time_slot }
            _params.pickup_time = JSON.stringify(obj)
            if (_params.order_deadline_time) {
              _params.order_deadline_time = _params.order_deadline_time / 1000
            }
          } else {//下单后指定时间自提
            const { type, lead_time, pickup_days, time_slot } = _params;
            const obj = { type, lead_time, pickup_days, time_slot }
            _params.pickup_time = JSON.stringify(obj)
            _params.order_deadline_time = null
          }
          API_goods.batchSetPickUpTime(_params, this.goodsIds).then(response => {
            this.$message.success("批量设置成功")
            this.$emit("close")
          })
        }
      })
    },
    deadlineChange (val) {
      if (val == 1) {
        this.baseInfoForm.order_deadline_time = null
      }
    },
    /**时间范围选择 */
    datatimeChange (time) {
      console.log(time);
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.baseInfoForm.starting_date =
            this.order_time_range[0] / 1000;
          this.baseInfoForm.ending_date =
            this.order_time_range[1] / 1000 +
            86400 - 1;
        } else {
          this.baseInfoForm.starting_date =
            this.order_time_range[0] / 1000;
          this.baseInfoForm.ending_date =
            this.order_time_range[1] / 1000 + 86400 - 1;
        }
      } else {
        this.baseInfoForm.starting_date = ''
        this.baseInfoForm.ending_date = ''
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/components/GoodsClassify/styles";

/deep/ .el-radio-group {
  margin-top: 6px;
}
</style>