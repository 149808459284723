<template>
  <div class="shop-goods-list-page">
    <en-table-layout ref="tableLayout" :tableData="pageData.data" :loading="loading" :search="true" @search="search"
      @selection-change="handleSelectionChange" @sort-change="handleSortChange">
      <template slot="header">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane v-for="(item, index) in navList" :key="index" :label="item.navigation_name"
            :name="item.navigation_id" :disabled="loading"></el-tab-pane>
        </el-tabs>
      </template>

      <!-- 企业跳站点的筛选条件 且 不是礼包 -->
      <template v-if="MixinIsFormEnterprise && +activeName != 3">
        <div slot="toolbar" class="row no-gutters align-items-center w-100">
          <div class="col">
            <el-form-item style="margin-bottom:0;margin-top:4px" label="毛利率(市价)">
              <el-input-number style="width: 110px" size="small" v-model="params.profit_min" placeholder="最低毛利率" />
              <span style="margin: 0 5px">-</span>
              <el-input-number style="width: 110px" size="small" v-model="params.profit_max" placeholder="最高毛利率" />
            </el-form-item>
            <el-form-item style="margin-bottom:0;margin-top:4px" label="利润率(销价)">
              <el-input-number style="width: 110px" size="small" v-model="params.shop_profit_min" placeholder="最低利润率" />
              <span style="margin: 0 5px">-</span>
              <el-input-number style="width: 110px" size="small" v-model="params.shop_profit_max" placeholder="最高利润率" />
            </el-form-item>
            <el-form-item style="margin-bottom:0;margin-top:4px">
              <el-input style="width: 300px" size="medium" placeholder="输入商品名称" v-model="keyword" clearable>
                <el-select slot="prepend" v-model="key_word" style="width: 120px">
                  <el-option value="goods_name" label="商品名称"></el-option>
                  <el-option value="goods_sn" label="商品编号"></el-option>
                  <el-option v-if="activeName == '0' && $store.getters.isSupplierName" value="supplier_name"
                    label="供应商名称"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-button style="margin-top:4px" @click="search" size="small" type="primary">
              搜索
            </el-button>
          </div>
          <div class="col-auto">
          </div>

        </div>
        <template slot="toolbar_btn">
          <el-dropdown @command="goodsbatchOps" size="small" style="margin:0 10px;">
            <el-button type="primary" size="small">
              批量操作 <i class="el-icon-arrow-down el-icon--right ml-2"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="modify">
                批量改价
              </el-dropdown-item>
              <el-dropdown-item command="on">
                批量上架
              </el-dropdown-item>
              <el-dropdown-item command="off">
                批量下架
              </el-dropdown-item>
              <el-dropdown-item command="remove">
                批量移除
              </el-dropdown-item>
              <el-dropdown-item command="openGoodsSale" :disabled="waitSold">
                批量开启单独售卖
              </el-dropdown-item>
              <el-dropdown-item command="closeGoodsSale" :disabled="waitSold">
                批量关闭单独售卖
              </el-dropdown-item>
              <el-dropdown-item command="settingTime" v-if="activeName == 7">
                批量设置自提时间
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button @click="addGoods" v-if="activeName == 7" type="primary" size="small">添加商品
          </el-button>
          <el-button @click="importShopGoods" v-if="activeName == 7" type="primary" size="small">批量导入
          </el-button>
          <span style="margin: 0 10px" v-if="hasReviseTask">改价任务进行中...</span>

          <el-button size="small" @click="exportShop" type="primary" :loading="importLoading">导出商品
          </el-button>
          <el-button v-if="activeName !== '2'" size="small" type="primary" @click="handleBatchCommand('clear')">下架全部无货商品
          </el-button>
        </template>
        <el-form slot="search">
          <el-row>
            <el-col :span="7"><el-form-item label="会员价" label-width="80px" v-if="MixinIsFormEnterprise">
                <el-input-number style="width: 100px" size="small" v-model="params.low_price" placeholder="最低会员价" />
                <span style="margin: 0 5px">-</span>
                <el-input-number style="width: 100px" size="small" v-model="params.high_price" placeholder="最高会员价" />
              </el-form-item></el-col>
            <el-col :span="7"><el-form-item label="市场价" label-width="100px">
                <el-input-number style="width: 100px" size="small" v-model="params.low_mktprice" placeholder="最低市场价" />
                <span style="margin: 0 5px">-</span>
                <el-input-number style="width: 100px" size="small" v-model="params.high_mktprice" placeholder="最高市场价" />
              </el-form-item></el-col>
            <el-col :span="9">
              <el-form-item label="价格关系" label-width="100px">
                <el-select style="width: 100px" size="small" class="choose-machine center" v-model="params.pre"
                  placeholder="选择价格" clearable>
                  <el-option v-for="item in list1" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <el-select style="width: 100px" size="small" class="choose-machine ml-2 center" v-model="params.sign"
                  placeholder="计算方式" clearable>
                  <el-option v-for="item in list2" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <el-select style="width: 100px" size="small" class="choose-machine ml-2 center" v-model="params.suf"
                  placeholder="选择价格" clearable>
                  <el-option v-for="item in list3" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7"><el-form-item label="商品状态" label-width="80px">
                <el-select size="small" class="choose-machine center" v-model="params.shop_goods_status"
                  placeholder="请选择" clearable style="width: 100px">
                  <el-option label="全部" value=""></el-option>
                  <el-option v-for="item in goodsStatusList" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select> </el-form-item></el-col>
            <el-col :span="7">
              <el-form-item label="是否单独售卖" v-if="activeName !== '2'" label-width="100px">
                <el-select size="small" class="choose-machine center" v-model="params.sold_separately_enable"
                  placeholder="请选择" style="width: 130px" clearable>
                  <el-option label="全部" value=""></el-option>
                  <el-option label="允许单独售卖" :value="1"></el-option>
                  <el-option label="不允许单独售卖" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>




            <el-col :span="7"><el-form-item v-if="activeName === '2'" label="商品配送类型" label-width="100px">
                <el-select style="width: 140px" v-model="params.ship_method" placeholder="请选择配送类型" clearable>
                  <el-option label="全部" value="" />
                  <el-option label="由厂家配送" :value="2" />
                  <el-option label="京东自营配送" :value="1" />
                </el-select> </el-form-item></el-col>
            <el-col :span="8">
              <el-form-item label="商品分类" label-width="100px">
                <el-cascader clearable style="width: 280px" v-model="categoryParams" :options="categoryProxy.children"
                  :props="categoryProxy.props" placeholder="请选择商品分类" class="cas-select" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7"><el-form-item v-if="activeName !== '2'" label="库存状态" label-width="80px">
                <el-select size="small" class="choose-machine center" v-model="params.has_goods" placeholder="请选择"
                  style="width: 100px">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="有货" :value="1"></el-option>
                </el-select> </el-form-item></el-col>
            <el-col :span="5"><el-form-item v-if="activeName == '7'" label="是否到店支付" label-width="100px">
                <el-select size="small" class="choose-machine center" v-model="params.offline_store_pay"
                  placeholder="请选择" style="width: 140px">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="否" :value="1"></el-option>
                  <el-option label="是" :value="2"></el-option>
                </el-select> </el-form-item></el-col>
            <el-col :span="7"><el-form-item v-if="activeName === '2'" label="显示类型" label-width="80px">
                <el-select :disabled="areaSelectData.province === ''" size="small" class="choose-machine center"
                  v-model="areaSelectData.jd_area_state" placeholder="请选择" style="width: 100px">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="有货" :value="1"></el-option>
                  <el-option label="无货" :value="2"></el-option>
                </el-select> </el-form-item></el-col>
            <el-col :span="7">
              <el-form-item label="是否单独售卖" v-if="activeName == '2'" label-width="100px">
                <el-select size="small" class="choose-machine center" v-model="params.sold_separately_enable"
                  placeholder="请选择" style="width: 140px" clearable>
                  <el-option label="全部" value=""></el-option>
                  <el-option label="允许单独售卖" :value="1"></el-option>
                  <el-option label="不允许单独售卖" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8"><el-form-item v-if="activeName === '2'" label="地区" label-width="100px">
                <el-select size="small" class="choose-machine center" v-model="areaSelectData.province"
                  placeholder="省/直辖市" style="width: 100px; margin-right: 5px" clearable>
                  <el-option v-for="(item, index) in this.provinceList" :label="item.name" :value="item.id"
                    :key="index">
                  </el-option>
                </el-select>
                <el-select v-if="areaNum > 1" size="small" class="choose-machine center" v-model="areaSelectData.city"
                  placeholder="城市/地区" style="width: 100px; margin-right: 5px" clearable>
                  <el-option v-for="(item, index) in this.cityList" :label="item.name" :value="item.id" :key="index">
                  </el-option>
                </el-select>
                <el-select v-if="areaNum > 2" size="small" class="choose-machine center" v-model="areaSelectData.county"
                  placeholder="县/区" style="width: 100px; margin-right: 5px" clearable>
                  <el-option v-for="(item, index) in this.countyList" :label="item.name" :value="item.id" :key="index">
                  </el-option>
                </el-select>
                <el-select v-if="areaNum > 3" size="small" class="choose-machine center" v-model="areaSelectData.town"
                  placeholder="乡镇/街道" style="width: 100px; margin-right: 5px" clearable>
                  <el-option v-for="(item, index) in this.townList" :label="item.name" :value="item.id" :key="index">
                  </el-option>
                </el-select> </el-form-item></el-col>


          </el-row>
        </el-form>
      </template>

      <!-- 单独登录站点的筛选条件 且 不是礼包 -->
      <template v-if="!MixinIsFormEnterprise && +activeName != 3">
        <template slot="toolbar">
          <div class="row no-gutters align-items-center">
            <el-form-item style="margin-bottom:0;margin-top:4px" v-if="!ispetroChina" label="分销价" label-width="68px">
              <el-input-number style="width: 110px" size="small" v-model="params.low_enterprice" placeholder="最低分销价" />
              <span style="margin: 0 5px">-</span>
              <el-input-number style="width: 110px" size="small" v-model="params.high_enterprice" placeholder="最高分销价" />
            </el-form-item>

            <el-form-item style="margin-bottom:0;margin-top:4px" label="市场价" label-width="68px">
              <el-input-number style="width: 110px" size="small" v-model="params.low_mktprice" placeholder="最低市场价" />
              <span style="margin: 0 5px">-</span>
              <el-input-number style="width: 110px" size="small" v-model="params.high_mktprice" placeholder="最高市场价" />
            </el-form-item>
          </div>
          <div class="col"></div>
          <div class="col-auto">
            <el-form-item style="margin-bottom:0;margin-top:4px">
              <el-input style="width: 320px" size="medium" placeholder="输入商品名称" v-model="keyword" clearable>
                <el-select slot="prepend" v-model="key_word" style="width: 120px">
                  <el-option value="goods_name" label="商品名称"></el-option>
                  <el-option value="goods_sn" label="商品编号"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-button style="margin-top:4px" @click="search" size="small" type="primary">
              搜索
            </el-button>
          </div>
        </template>
        <template slot="toolbar_btn">
          <el-button @click="addGoods" v-if="activeName == 7" type="primary" size="small">添加商品
          </el-button>
          <el-button @click="importShopGoods" v-if="activeName == 7" type="primary" size="small">批量导入
          </el-button>
          <el-dropdown @command="goodsbatchOps" size="small" style="margin:0 10px;">
            <el-button type="primary" size="small">
              批量操作 <i class="el-icon-arrow-down el-icon--right ml-2"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="modify">
                批量改价
              </el-dropdown-item>
              <el-dropdown-item command="on">
                批量上架
              </el-dropdown-item>
              <el-dropdown-item command="off">
                批量下架
              </el-dropdown-item>
              <el-dropdown-item command="remove">
                批量移除
              </el-dropdown-item>
              <el-dropdown-item command="openGoodsSale" :disabled="waitSold">
                批量开启单独售卖
              </el-dropdown-item>
              <el-dropdown-item command="closeGoodsSale" :disabled="waitSold">
                批量关闭单独售卖
              </el-dropdown-item>
              <el-dropdown-item command="settingTime" v-if="activeName == 7">
                批量设置自提时间
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span style="margin: 0 10px" v-if="hasReviseTask">改价任务进行中...</span>
          <el-button size="small" @click="exportShop" type="primary" :loading="importLoading">导出商品
          </el-button>
          <el-button v-if="activeName !== '2'" size="small" type="primary" @click="handleBatchCommand('clear')">下架全部无货商品
          </el-button>
        </template>
        <el-form slot="search">
          <div class="row no-gutters align-items-center" style="margin-top: 8px">
            <el-row>
              <el-col :span="9"><el-form-item label="商品分类" label-width="100px">
                  <el-cascader clearable style="width: 320px" v-model="categoryParams" :options="categoryProxy.children"
                    :props="categoryProxy.props" placeholder="请选择商品分类" class="cas-select" /> </el-form-item></el-col>
              <el-col :span="5"><el-form-item label="商品状态" label-width="100px">
                  <el-select size="small" class="choose-machine center" v-model="params.shop_goods_status"
                    placeholder="请选择" clearable style="width: 100px">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="item in goodsStatusList" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select> </el-form-item></el-col>

              <el-col :span="9">
                <el-form-item label="价格关系" label-width="100px">
                  <el-select style="width: 100px" size="small" class="choose-machine center" v-model="params.pre"
                    placeholder="选择价格" clearable>
                    <el-option v-for="item in list1" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                  <el-select style="width: 100px" size="small" class="choose-machine ml-2 center" v-model="params.sign"
                    placeholder="计算方式" clearable>
                    <el-option v-for="item in list2" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                  <el-select style="width: 100px" size="small" class="choose-machine ml-2 center" v-model="params.suf"
                    placeholder="选择价格" clearable>
                    <el-option v-for="item in list3" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5"><el-form-item v-if="activeName !== '2'" label="库存状态" label-width="100px">
                  <el-select size="small" class="choose-machine center" v-model="params.has_goods" placeholder="请选择"
                    style="width: 100px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="有货" :value="1"></el-option>
                  </el-select> </el-form-item></el-col>
              <el-col :span="6">
                <el-form-item label="是否单独售卖" v-if="activeName !== '2'" label-width="100px">
                  <el-select size="small" class="choose-machine center" v-model="params.sold_separately_enable"
                    placeholder="请选择" style="width: 130px" clearable>
                    <el-option label="全部" value=""></el-option>
                    <el-option label="允许单独售卖" :value="1"></el-option>
                    <el-option label="不允许单独售卖" :value="0"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8"><el-form-item v-if="activeName == '7'" label="是否到店支付" label-width="100px">
                  <el-select size="small" class="choose-machine center" v-model="params.offline_store_pay"
                    placeholder="请选择" style="width: 100px">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="否" :value="1"></el-option>
                    <el-option label="是" :value="2"></el-option>
                  </el-select> </el-form-item></el-col>
              <el-col :span="7">
                <el-form-item v-if="activeName === '2'" label="商品配送类型" label-width="100px">
                  <el-select style="width: 215px" v-model="params.ship_method" placeholder="请选择配送类型" clearable>
                    <el-option label="全部" value="" />
                    <el-option label="由厂家配送" :value="2" />
                    <el-option label="京东自营配送" :value="1" />
                  </el-select> </el-form-item></el-col>
              <el-col :span="12">
                <el-form-item v-if="activeName === '2'" label="地区" label-width="100px">
                  <el-select size="small" class="choose-machine center" v-model="areaSelectData.province"
                    placeholder="省/直辖市" style="width: 100px; margin-right: 5px" clearable>
                    <el-option v-for="(item, index) in this.provinceList" :label="item.name" :value="item.id"
                      :key="index">
                    </el-option>
                  </el-select>
                  <el-select v-if="areaNum > 1" size="small" class="choose-machine center" v-model="areaSelectData.city"
                    placeholder="城市/地区" style="width: 100px; margin-right: 5px" clearable>
                    <el-option v-for="(item, index) in this.cityList" :label="item.name" :value="item.id" :key="index">
                    </el-option>
                  </el-select>
                  <el-select v-if="areaNum > 2" size="small" class="choose-machine center"
                    v-model="areaSelectData.county" placeholder="县/区" style="width: 100px; margin-right: 5px" clearable>
                    <el-option v-for="(item, index) in this.countyList" :label="item.name" :value="item.id"
                      :key="index">
                    </el-option>
                  </el-select>
                  <el-select v-if="areaNum > 3" size="small" class="choose-machine center" v-model="areaSelectData.town"
                    placeholder="乡镇/街道" style="width: 100px; margin-right: 5px" clearable>
                    <el-option v-for="(item, index) in this.townList" :label="item.name" :value="item.id" :key="index">
                    </el-option>
                  </el-select> </el-form-item>
              </el-col>
              <el-form-item v-if="activeName === '2' && areaSelectData.province !== ''" label="显示类型"
                label-width="100px">
                <el-select size="small" class="choose-machine center" v-model="areaSelectData.jd_area_state"
                  placeholder="请选择" style="width: 100px">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="有货" :value="1"></el-option>
                  <el-option label="无货" :value="2"></el-option>
                </el-select> </el-form-item>
            </el-row>
            <el-row>
              <!------------  getArea------------>

              <el-col :span="6"></el-col>
              <el-col :span="4" v-if="activeName === '2'">
                <el-form-item label="是否单独售卖" label-width="100px">
                  <el-select size="small" class="choose-machine center" v-model="params.sold_separately_enable"
                    placeholder="请选择" style="width: 130px" clearable>
                    <el-option label="全部" value=""></el-option>
                    <el-option label="允许单独售卖" :value="1"></el-option>
                    <el-option label="不允许单独售卖" :value="0"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

          </div>
        </el-form>
      </template>

      <!-- 礼包 -->
      <template v-if="+activeName == 3">
        <template slot="toolbar">
          <div class="row no-gutters align-items-center">
            <el-form-item style="margin-bottom:0;margin-top:4px" v-if="!(!MixinIsFormEnterprise && ispetroChina)"
              label="分销总价" label-width="68px">
              <el-input-number style="width: 130px" size="small" v-model="gift.lowEnterPrice" :precision="2" clearable
                placeholder="￥分销总价最小" />
              <span style="margin: 0 5px">-</span>
              <el-input-number style="width: 130px" size="small" v-model="gift.highEnterPrice" :precision="2" clearable
                placeholder="￥分销总价最大" />
            </el-form-item>
            <el-form-item style="margin-bottom:0;margin-top:4px" label="市场总价" label-width="68px">
              <el-input-number style="width: 130px" size="small" v-model="gift.lowMktPrice" :precision="2" clearable
                placeholder="￥市场总价最小" />
              <span style="margin: 0 5px">-</span>
              <el-input-number style="width: 130px" size="small" v-model="gift.highMktPrice" :precision="2" clearable
                placeholder="￥市场总价最大" />
            </el-form-item>
            <el-form-item style="margin-bottom:0;margin-top:4px" label="礼包分类" label-width="100px">
              <el-cascader clearable style="width: 215px;" v-model="giftCategoryParams" :options="JDCategory.children"
                :props="JDCategory.props" placeholder="请选择礼包分类" class="cas-select" />
            </el-form-item>

          </div>
        </template>
        <template slot="toolbar_btn">
          <el-form-item label="区域偏好">
            <el-input placeholder="请输入关键词进行搜索" v-model.trim="gift.labelNamesArea" clearable></el-input>
          </el-form-item>
          <el-form-item label="套餐类型">
            <el-select v-model="gift.comboType" style="width: 100px" clearable>
              <el-option value label="全部"></el-option>
              <el-option :value="1" label="京东"></el-option>
              <el-option :value="2" label="线下品"></el-option>
              <el-option :value="3" label="京东+线下品"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否异常">
            <el-select v-model="gift.normalStatus" style="width: 100px">
              <el-option value label="全部"></el-option>
              <el-option :value="0" label="异常"></el-option>
              <el-option :value="1" label="正常"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="col-auto">
            <el-input style="width: 290px" size="small" placeholder="输入关键字" v-model="gift.keyword" clearable>
              <el-select slot="prepend" v-model.trim="gift.key_word" style="width: 125px">
                <el-option value="gift_name" label="礼包名称"></el-option>
                <el-option value="id" label="礼包编号"></el-option>
                <el-option value="web_gift_name" label="商品前端名称"></el-option>
                <el-option value="goods_name" label="商品名称"></el-option>
                <el-option value="remark" label="备注信息"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item class="col-auto">
            <el-button @click="giftSearch" type="primary" size="small">搜索</el-button>
          </el-form-item>
          <br />
          <div class="col-auto ml-2" style="min-width:300px">
            <el-button @click="addGiftGodds" size="small" type="primary">
              新建礼包
            </el-button>
            <el-dropdown @command="batchOps" size="small" style="margin-left: 10px;">
              <el-button type="primary" size="small">
                批量操作 <i class="el-icon-arrow-down el-icon--right ml-2"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="open" v-if="force_exchange_goods_open">
                  批量开启智能换货
                </el-dropdown-item>
                <el-dropdown-item command="close" v-if="force_exchange_goods_open">
                  批量关闭智能换货
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown v-if="!(!MixinIsFormEnterprise && ispetroChina)" @command="exportbatchOps" size="small"
              style="margin-left: 10px;">
              <el-button type="primary" size="small">
                导出套餐方案 <i class="el-icon-arrow-down el-icon--right ml-2"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="exportPPT">导出PPT方案1</el-dropdown-item>
                <el-dropdown-item command="twoExportPPT">导出PPT方案2</el-dropdown-item>
                <el-dropdown-item command="exportExcel">导出Excel方案</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
        <el-form slot="search">
          <div class="row no-gutters align-items-center" style="margin-top:8px;">
            <el-row>
              <el-col :span="7">
                <el-form-item label="销售总价" label-width="100px">
                  <el-input-number style="width: 100px;" size="small" v-model="gift.low_price" placeholder="销售总价最小" />
                  <span style="margin: 0 5px;">-</span>
                  <el-input-number style="width: 100px;" size="small" v-model="gift.high_price" placeholder="销售总价最大" />
                </el-form-item>
              </el-col>
              <el-col v-if="MixinIsFormEnterprise" :span="7">
                <el-form-item label="分销利润率" label-width="100px">
                  <el-input-number style="width: 100px;" size="small" v-model="gift.lowDistributionProfit"
                    placeholder="最低利润率" />
                  <span style="margin: 0 5px;">-</span>
                  <el-input-number style="width: 100px;" size="small" v-model="gift.highDistributionProfit"
                    placeholder="最高利润率" />
                </el-form-item>
              </el-col>
              <el-col v-if="MixinIsFormEnterprise" :span="7">
                <el-form-item label="销售价利润率" label-width="100px">
                  <el-input-number style="width: 100px;" size="small" v-model="gift.lowPriceProfit"
                    placeholder="最低利润率" />
                  <span style="margin: 0 5px;">-</span>
                  <el-input-number style="width: 100px;" size="small" v-model="gift.highPriceProfit"
                    placeholder="最高利润率" />
                </el-form-item>
              </el-col>
              <el-col v-if="MixinIsFormEnterprise" :span="7">
                <el-form-item label="市场利润率" label-width="100px">
                  <el-input-number style="width: 100px;" size="small" v-model="gift.lowMarketProfit"
                    placeholder="最低利润率" />
                  <span style="margin: 0 5px;">-</span>
                  <el-input-number style="width: 100px;" size="small" v-model="gift.highMarketProfit"
                    placeholder="最高利润率" />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="创建时间" class="col-auto" size="small">
                  <el-date-picker style="width: 220px" v-model="gift.time_range" type="daterange" size="small"
                    range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeGiftTime"
                    :picker-options="pickerOptions" clearable></el-date-picker>
                </el-form-item>

              </el-col>
              <el-col :span="7">
                <el-form-item class="col-auto" label="所属公司">
                  <el-input style="width: 200px;" placeholder="请输入所属公司" v-model.trim="gift.company" clearable
                    size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="状态" label-width="68px">
                  <el-select v-model="gift.comboStatus" clearable style="width: 100px">
                    <el-option value="" label="全部"></el-option>
                    <el-option value="1" label="启用"></el-option>
                    <el-option value="0" label="禁用"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="是否开启智能换货" class="col-auto" size="small" v-if="force_exchange_goods_open">
                  <el-select v-model="gift.goodsExchange" size="small" style="width: 110px" clearable>
                    <el-option value="" label="全部"></el-option>
                    <el-option value="0" label="未开启"></el-option>
                    <el-option value="1" label="已开启"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </template>

      <template slot="table-columns" v-if="+activeName != 3">
        <el-table-column type="selection" />

        <el-table-column label="图片" width="60" fixed="left" class-name="goods-cover-wrapper">
          <template slot-scope="{ row }">
            <el-popover placement="right" trigger="hover">
              <img :src="row.thumbnail" alt="" style="width: 300px" />
              <div @click="opengoodsclassify(row)" slot="reference">
                <img :src="row.thumbnail" class="goods-cover" alt="" />
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="商品名称" width="215px" :show-overflow-tooltip="true" fixed="left">
          <template slot-scope="{ row }">
            {{ row.goods_name.replaceAll("amp;", "").replaceAll(" ", "&nbsp;") }}
          </template>
        </el-table-column>

        <el-table-column label="商品编号" width="120" fixed="left" prop="good_sn" />
        <el-table-column label="商品分类" width="120" fixed="left" prop="cat_name" />
        <el-table-column label="匹配礼包数" width="120" sortable="custom" align="center" prop="matchGiftNum">
          <template slot-scope="{ row }">
            {{ row.match_gift_num || 0 }}
          </template>
        </el-table-column>

        <el-table-column label="商品来源" width="80" :show-overflow-tooltip="true">
          <template slot-scope="{ row }">
            <template v-if="row.goods_source === 0"> 平台方 </template>
            <template v-if="row.goods_source === 1"> 京东 </template>
            <template v-if="row.goods_source === 9"> 自有 </template>
          </template>
        </el-table-column>

        <el-table-column label="所属供应商" width="100" show-overflow-tooltip
          v-if="activeName === '0' && MixinIsFormEnterprise && $store.getters.isSupplierName">
          <template slot-scope="scope">
            {{ scope.row.seller_name }}
          </template>
        </el-table-column>

        <el-table-column v-if="MixinIsFormEnterprise" label="会员价" width="100" prop="price" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.price | unitPrice("￥") }}
          </template>
        </el-table-column>

        <el-table-column v-if="!(!MixinIsFormEnterprise && ispetroChina)" label="分销价" width="100" prop="enterprisePrice"
          sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.enterprise_revise_price | unitPrice("￥") }}
          </template>
        </el-table-column>

        <el-table-column label="市场价" width="100" prop="market" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.mktprice | unitPrice("￥") }}
          </template>
        </el-table-column>

        <el-table-column v-if="MixinIsFormEnterprise" label="毛利率" width="100" prop="profit" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.enterprise_profit || 0 }}%
          </template>

          <template slot="header" slot-scope="{ row }">
            <el-tooltip effect="dark" content="毛利率 =（市场价 - 会员价）/ 市场价 * 100%" placement="top">
              <label class="cursor-pointer">毛利率</label>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="销售价" width="100" prop="revisePrice" sortable="custom">
          <template slot-scope="scope">
            <span v-if="scope.row.goods_type === 'POINT'">
              {{ scope.row.revise_exchange_money | unitPrice("￥") }} +
              {{ scope.row.revise_exchange_point || 0 }}
              积分
            </span>
            <span v-else>{{ scope.row.revise_price | unitPrice("￥") }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="MixinIsFormEnterprise" label="利润率" width="100" prop="shopProfit" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.shop_profit || 0 }}%
          </template>

          <template slot="header" slot-scope="{ row }">
            <el-tooltip effect="dark" content="利润率 =（销售价 - 会员价）/ 销售价 * 100%" placement="top">
              <label class="cursor-pointer">利润率</label>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="可售库存" width="100">
          <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
          <template slot-scope="scope">
            <template v-if="scope.row.goods_source === 1">
              <template v-if="quantityStatus">{{
      scope.row.stock_state_desc
    }}</template>
              <template v-else>
                <span v-if="scope.row.enable_quantity > 0">有货</span>
                <span v-else>无货</span>
              </template>
            </template>
            <template v-else> {{ scope.row.enable_quantity }}件 </template>
          </template>
        </el-table-column>

        <el-table-column label="实际库存" width="100">
          <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
          <template slot-scope="scope">
            <template v-if="scope.row.goods_source === 1">
              <template v-if="quantityStatus">{{
      scope.row.stock_state_desc
    }}</template>
              <template v-else>
                <span v-if="scope.row.enable_quantity > 0">有货</span>
                <span v-else>无货</span>
              </template>
            </template>
            <template v-else> {{ scope.row.quantity || 0 }}件 </template>
          </template>
        </el-table-column>
        <el-table-column label="终身限购数" width="150" prop="restrict_num">
          <template slot="header">
            <el-tooltip style="max-width: 200px" effect="dark" placement="top">
              <label class="cursor-pointer">终身限购数<i class="el-icon-warning-outline"></i></label>
              <div slot="content">
                终身限购数为C端用户终身可在商城内购买同一个商品的总数量，超出则<br />不再允许C端用户购买。限购输入框中不填默认为不限。若后台修改已设<br />置好的限购数，则C端用户已购买数量重新开始统计。
              </div>
            </el-tooltip>
          </template>
          <template slot-scope="{ row, $index }">
            <el-input style="width: 90px" v-if="activeIndex === $index" v-model="restrictNum" size="mini"
              @input="changNum($event, $index)" @focus="restrictChang($index)"></el-input>
            <el-input style="width: 90px" v-else-if="`${row.restrict_num}` === '-1'" value="不限" size="mini"
              @focus="restrictChang($index)"></el-input>
            <el-input style="width: 90px" v-else :value="row.restrict_num" size="mini"
              @focus="restrictChang($index)"></el-input>
            <el-button v-show="activeIndex === $index" class="icon-btn" icon="el-icon-check" circle
              @click="restrictBlur($index)"></el-button>
            <el-button v-show="activeIndex === $index" class="icon-btn" icon="el-icon-close" circle
              @click="restrictCancel($index)"></el-button>
            <!-- <el-input>{{ `${row.restrict_num}`==='-1'?'不限制':row.restrict_num }}</el-input> -->
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="130" prop="time" sortable="custom">
          <template slot-scope="scope">{{
      scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm")
    }}</template>
        </el-table-column>

        <el-table-column label="总销售" width="100" prop="buynum" sortable="custom">
          <template slot-scope="scope">{{ scope.row.buy_count }}</template>
        </el-table-column>

        <el-table-column label="单独售卖" width="100" align="center">
          <template slot="header">
            <el-tooltip effect="dark" placement="top">
              <label class="cursor-pointer">单独售卖<i class="el-icon-warning-outline"></i></label>
              <div slot="content">
                单独售卖开关关闭时，该商品不允许移动端用户单独购买，只<br />允许以礼包内商品的形式购买。开关开启时，则允许移动端用<br />户单独购买此商品。
              </div>
            </el-tooltip>
          </template>
          <template slot-scope="{ row }">
            <el-switch :disabled="waitSold" v-model="row.sold_separately_enable" :active-value="1" :inactive-value="0"
              @change="changeGoodsSale($event, row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column v-if="activeName == '7'" label="是否到店支付" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.offline_store_pay == 1 ? '否' : '是' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row | marketStatus }}</span>
            <div class="under-reason" v-if="scope.row.shop_goods_status === 0" @click="showUnderReason(scope.row)">
              (下架原因)
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column label="上架状态" width="80" v-if="!MixinIsFormEnterprise">
          <template slot-scope="scope">
            <span>{{ scope.row | marketEnable }}</span>
          </template>
        </el-table-column>
        <el-table-column label="审核状态" width="80" v-if="!MixinIsFormEnterprise">
          <template slot-scope="scope">
            <span>{{ scope.row | authStatus }}</span>
          </template>
        </el-table-column>-->
        <el-table-column label="操作" fixed="right" :render-header="renderHeader" :key="Math.random()">
          <template slot-scope="scope">
            <div class="operate" style="white-space: nowrap; display: inline-block">

              <!--<el-button
							size="mini"
							type="success"
							@click="handleEditGoods(scope.row)">详细
            </el-button>-->
              <el-tooltip v-if="!MixinIsFormEnterprise" class="item" effect="dark" content="改价" placement="top-start">
                <el-button type="text" size="mini" @click="revisePriceShow([scope.row.id], false)"
                  :disabled="hasReviseTask">
                  <img :src="editprice_icon" alt="">
                </el-button>
              </el-tooltip>

              <el-tooltip v-if="MixinIsFormEnterprise" class="item" effect="dark" content="改价" placement="top-start">
                <el-button type="text" size="mini" @click="revisePriceShow([scope.row.goods_id], false)"
                  :disabled="hasReviseTask">
                  <img :src="editprice_icon" alt="">
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" v-if="activeName == 7 && scope.row.shop_goods_status === 0" effect="dark"
                content="编辑" placement="top-start">
                <el-button size="mini" type="text" @click="editGoods(scope.row)"><img :src="edit_icon" alt="">
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" v-if="activeName == 7 && scope.row.shop_goods_status === 0" effect="dark"
                content="删除" placement="top-start">
                <el-button size="mini" type="text" @click="handleDeleteGoods(scope.row)"><img :src="delete_icon" alt="">
                </el-button>
              </el-tooltip>

              <el-tooltip class="item" effect="dark" v-if="scope.row.shop_goods_status === 1" content="下架"
                placement="top-start">
                <el-button size="mini" type="text" @click="handleUnderGoods(scope.row)" :disabled="hasReviseTask">
                  <img :src="down_icon" alt="">
                </el-button>
              </el-tooltip>

              <el-tooltip class="item" v-if="scope.row.shop_goods_status === 0" effect="dark" content="上架"
                placement="top-start">
                <el-button size="mini" type="text" @click="handleUpGoods(scope.row)" :disabled="hasReviseTask">
                  <img :src="up_icon" alt="">
                </el-button>
              </el-tooltip>

              <el-tooltip class="item" v-if="![7, '7'].includes(activeName)" effect="dark" content="移除"
                placement="top-start">
                <el-button size="mini" type="text" @click="handleDeleteGoods(scope.row)" :disabled="hasReviseTask">
                  <img :src="remove_icon" alt="">
                </el-button>
              </el-tooltip>
              <!-- <el-button
							type="primary"
							size="mini"
							@click="handleStockGoods(scope.row)">库存
						</el-button>
						<el-button
							v-if="distributionSet"
							type="primary"
							size="mini"
							@click="handleRebate(scope.row)">返利
            </el-button>-->
            </div>
          </template>
        </el-table-column>
      </template>

      <template slot="table-columns" v-if="+activeName == 3">
        <el-table-column type="selection" />

        <el-table-column label="编号" type="index" fixed="left" key="index" width="50" v-if="!MixinIsFormEnterprise">
          <template slot-scope="{ row }">
            {{ row.id }}
          </template>
        </el-table-column>

        <el-table-column label="礼包图片" width="90" fixed="left" class-name="goods-cover-wrapper" key="thumbnail">
          <template slot-scope="{ row }">
            <el-popover placement="right" trigger="hover">
              <img :src="row.thumbnail" alt="" style="width: 300px" />
              <div slot="reference">
                <img :src="row.thumbnail" class="goods-cover" alt="" />
                <img class="goods-tag" :src="getRowTagImage(row)" alt />
              </div>
            </el-popover>
          </template>
        </el-table-column>



        <el-table-column label="礼包名称" width="180" :show-overflow-tooltip="true" prop="gift_name" fixed="left"
          key="gift_name"></el-table-column>

        <el-table-column label="前端礼包名称" width="180" :show-overflow-tooltip="true" :key="'web_gift_name'">
          <template slot-scope="scope">
            <div v-if="!scope.row.showWebNameInput" @click="_ => $set(scope.row, 'showWebNameInput', true)"
              style="width:100%;min-height:20px">
              {{ scope.row.web_gift_name }}</div>
            <el-input v-else v-model.trim="scope.row.web_gift_name" :maxlength="32" v-focus
              @blur="changeWebName(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="礼包分类" width="180" key="category_name" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.category_name }}
          </template>
        </el-table-column>
        <el-table-column label="商品种类" align="center" width="120" prop="goods_kind_num">
          <template slot-scope="scope">
            <span v-if="scope.row.goods_kind_num" class="kind-num" @click="showKindDialog(scope.row.id)">{{
      scope.row.goods_kind_num }}</span>
            <span v-else class="kind-num" style="cursor: default;">0</span>
          </template>
        </el-table-column>
        <el-table-column v-if="!(!MixinIsFormEnterprise && ispetroChina)" label="分销总价" width="120"
          prop="enterprisePrice" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.enterprise_sum_price | unitPrice("￥") }}
          </template>
        </el-table-column>
        <el-table-column v-if="MixinIsFormEnterprise" label="分销利润率" width="130" prop="distributionProfit"
          sortable="custom" :key="'distribution_profit'">
          <template slot="header">
            <el-tooltip effect="dark" placement="top">
              <label class="cursor-pointer"><i class="el-icon-warning-outline"></i>分销利润率</label>
              <div slot="content">
                分销利润率 = (分销总价-会员总价) /分销总价*100%
              </div>
            </el-tooltip>
          </template>
          <template slot-scope="scope">{{ scope.row.distribution_profit ? scope.row.distribution_profit.toFixed(2) : 0
            }}%</template>
        </el-table-column>
        <el-table-column label="销售总价" width="120" prop="shopPrice" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.shop_sum_price | unitPrice("￥") }}
          </template>
        </el-table-column>
        <el-table-column v-if="MixinIsFormEnterprise" label="销售利润率" width="130" prop="priceProfit" sortable="custom"
          :key="'price_profit'">
          <template slot="header">
            <el-tooltip effect="dark" placement="top">
              <label class="cursor-pointer"><i class="el-icon-warning-outline"></i>销售利润率</label>
              <div slot="content">
                销售利润率 = (销售总价-会员总价) /销售总价*100%
              </div>
            </el-tooltip>
          </template>
          <template slot-scope="scope">{{ scope.row.price_profit ? scope.row.price_profit.toFixed(2) : 0 }}%</template>
        </el-table-column>
        <el-table-column label="会员总价" width="120" prop="memberPrice" sortable="custom" v-if="MixinIsFormEnterprise">
          <template slot-scope="scope">
            {{ scope.row.sum_price | unitPrice("￥") }}
          </template>
        </el-table-column>
        <el-table-column label="市场总价" width="120" prop="marketPrice" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.market_sum_price | unitPrice("￥") }}
          </template>
        </el-table-column>
        <el-table-column v-if="MixinIsFormEnterprise" label="市场利润率" width="130" prop="marketProfit" sortable="custom"
          :key="'market_profit'">
          <template slot="header">
            <el-tooltip effect="dark" placement="top">
              <label class="cursor-pointer"><i class="el-icon-warning-outline"></i>市场利润率</label>
              <div slot="content">
                市场利润率 = (市场总价-会员总价) /市场总价*100%
              </div>
            </el-tooltip>
          </template>
          <template slot-scope="scope">{{ scope.row.market_profit ? scope.row.market_profit.toFixed(2) : 0
            }}%</template>
        </el-table-column>
        <!-- <el-table-column label="套餐价" width="120" prop="comboPrice" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.combo_price | unitPrice("￥") }}
          </template>
        </el-table-column> -->

        <!-- <el-table-column label="利润率" width="120" prop="shop_profit" sortable="custom" v-if="MixinIsFormEnterprise">
          <template slot-scope="{ row }"> {{ row.shop_profit }}% </template>
          <template slot="header">
            <el-tooltip effect="dark" content="利润率 = （套餐价 - 会员总价）/ 套餐价 *100%" placement="top">
              <label class="cursor-pointer">利润率</label>
            </el-tooltip>
          </template>
        </el-table-column> -->
        <el-table-column label="套餐类型" width="160" prop="combo_type" :key="'combo_type'">
          <template slot-scope="scope">
            <span v-if="scope.row.combo_type == 1">京东</span>
            <span v-else-if="scope.row.combo_type == 2">线下品</span>
            <span v-else-if="scope.row.combo_type == 3">京东+线下品</span>
          </template>
        </el-table-column>
        <el-table-column label="区域偏好" width="160" show-overflow-tooltip prop="label_names_area"
          :key="'label_names_area'" />
        <el-table-column label="标签" width="160" show-overflow-tooltip prop="label_names_gift"
          :key="'label_names_gift'" />
        <el-table-column label="销量" width="120" prop="salesVolume" align="center" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.sales_volume || 0 }}
          </template>
        </el-table-column>

        <el-table-column label="创建时间" width="130" prop="createTime" sortable="custom">
          <template slot-scope="scope">{{
      scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm")
    }}</template>
        </el-table-column>

        <el-table-column label="更新时间" width="130" prop="updateTime" sortable="custom">
          <template slot-scope="scope">{{
        scope.row.update_time | unixToDate("yyyy-MM-dd hh:mm")
      }}</template>
        </el-table-column>

        <el-table-column label="礼包状态" width="100" prop="" align="center">
          <template slot-scope="{ row }">
            {{ ["禁用", "启用"][row.status] }}
          </template>
        </el-table-column>

        <el-table-column label="开启智能换货" width="120" align="center" v-if="force_exchange_goods_open">
          <template slot-scope="{ row }">
            <el-switch :disabled="waitExchange" v-model="row.goods_exchange" :active-value="1" :inactive-value="0"
              @change="changeGoodsExchange($event, row)"></el-switch>
          </template>
        </el-table-column>

        <el-table-column label="备注信息" width="200" prop="remark">
          <template slot-scope="{ row }">
            <div class="remark-warper" @click="editGiftRemark(row)">
              <el-tooltip placement="top">
                <p slot="content" style="margin: 0" v-show="row.remark">
                  {{ row.remark || "" }}
                </p>
                <p class="gift-remark">
                  {{ row.remark || "" }}
                </p>
              </el-tooltip>
              <span class="no-remark" v-show="!row.remark">请填写备注信息</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" :render-header="renderHeader" :key="Math.random()" fixed="right">
          <template slot-scope="{ row }">
            <div class="operate" style="white-space: nowrap; display: inline-block">
              <el-tooltip class="item" effect="dark" content="复制" placement="top-start">
                <el-button type="text" size="mini" @click="showCopyGiftBox(row)">
                  <img :src="copy_icon" alt="">
                </el-button>
              </el-tooltip>

              <el-tooltip class="item" effect="dark" content="编辑" placement="top-start">
                <el-button type="text" size="mini" @click="toEditGiftGoods(row)">
                  <img :src="edit_icon" alt="">
                </el-button>
              </el-tooltip>

              <el-tooltip v-if="row.status == 0" class="item" effect="dark" content="启用" placement="top-start">
                <el-button type="text" size="mini" @click="openGiftGoods(row)">
                  <img :src="enable_icon" alt="">
                </el-button>
              </el-tooltip>

              <el-tooltip v-if="row.status == 1" class="item" effect="dark" content="禁用" placement="top-start">
                <el-button type="text" size="mini" @click="closeGiftGoods(row)">
                  <img :src="disabled_icon" alt="">
                </el-button>
              </el-tooltip>

              <el-tooltip v-if="row.status == 0" class="item" effect="dark" content="删除" placement="top-start">
                <el-button type="text" size="mini" @click="delGiftGoods(row)">
                  <img :src="delete_icon" alt="">
                </el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>

    <!--库存编辑-->
    <el-dialog title="库存编辑" :visible.sync="goodsStockshow" width="35%" class="pop-sku">
      <div align="center">
        <el-form :model="goodsStockData" ref="goodsStockData" v-if="goodsStocknums === 1" style="width: 50%"
          label-width="100px" :rules="rules">
          <el-form-item label="库存" prop="quantity">
            <el-input v-model="goodsStockData.quantity" />
          </el-form-item>
          <el-form-item label="待发货数">
            <el-input v-model="goodsStockData.deliver_goods_quantity" disabled />
          </el-form-item>
        </el-form>
        <en-table-layout :table-data="goodsStockData" :loading="loading" border v-if="goodsStocknums > 1"
          :span-method="arraySpanMethod" :stripe="false">
          <template slot="table-columns">
            <el-table-column v-for="(item, index) in goodsStockTitle" v-show="item.prop !== 'sku_id'"
              :label="item.label" :key="index">
              <template slot-scope="scope">
                <el-input v-if="item.prop === 'quantity'" @blur="checkQuantity(scope.row.quantity)"
                  v-model="scope.row.quantity" />
                <span v-if="item.prop !== 'quantity'">
                  {{ scope.row[item.prop] }}
                </span>
              </template>
            </el-table-column>
          </template>
        </en-table-layout>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="goodsStockshow = false">取 消</el-button>
        <el-button type="primary" @click="reserveStockGoods">确 定 </el-button>
      </div>
    </el-dialog>

    <!--下架原因-->
    <el-dialog title="下架原因" :visible.sync="isShowUnderReason" width="17%">
      <div align="center">{{ under_reason ? under_reason : "无" }}</div>
    </el-dialog>

    <!--改价-->
    <x-dialog :proxy="modifyPriceDialogRef">
      <el-form :model="revisePriceForm" ref="revisePriceForm" label-width="80px">
        <el-form-item label="挑选范围" prop="cou" v-show="batchModifyPrice">
          <el-radio-group v-model="cou">
            <el-radio :label="1">
              已勾选的商品{{ "(共" + idsList.length + "件商品)" }}
            </el-radio>
            <el-radio :label="2">
              所有商品{{ "(共" + pageData.data_total + "件商品)" }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="销售价" prop="revise_value">
          <div class="row no-gutters">
            <div class="col-auto">按照</div>
            <el-select v-model="revisePriceForm.price_type" clearable placeholder="请选择"
              style="width: 100px; margin-left: 8px">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <el-select v-model="revisePriceForm.symbol" clearable placeholder="请选择"
              style="width: 90px; margin-left: 8px">
              <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <el-input v-show="revisePriceForm.symbol !== 'EQUAL'" style="width: 160px; margin-left: 8px"
              placeholder="请输入内容" @change="handleInputChange" v-model="revisePriceForm.ratio" clearable></el-input>
            <div class="col-auto" style="margin-left: 8px" v-show="revisePriceForm.symbol !== 'EQUAL'">
              %
            </div>
          </div>
        </el-form-item>
      </el-form>
      <!--      <div slot="footer" class="text-picker-footer">-->
      <!--        <el-button @click="isRevisePrice = false">取 消</el-button>-->
      <!--        <el-button type="primary" @click="revisePrice">确 定</el-button>-->
      <!--      </div>-->
    </x-dialog>

    <!--分销返利-->
    <el-dialog title="分销返利" :visible.sync="isShowDisRebate" width="24%">
      <el-form :model="disRebateData" label-width="100px" :rules="disRules" ref="disRebateData" status-icon>
        <el-form-item label="1级返利" prop="grade1Rebate">
          <el-input v-model="disRebateData.grade1Rebate">
            <template slot="prepend"> ¥ </template>
          </el-input>
        </el-form-item>
        <el-form-item label="2级返利" prop="grade2Rebate">
          <el-input v-model="disRebateData.grade2Rebate">
            <template slot="prepend"> ¥ </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="reserveDisSet('disRebateData')">
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <en-goods-picker type="seller" shopGoods :show="showDialog" :api="goodsApi" :categoryApi="categoryApi"
      :multipleApi="multipleApi" :defaultData="goodsIds" :limit="maxsize" @confirm="refreshFunc"
      @close="showDialog = false" />

    <!--改价-->
    <en-gaijia-picker :showm="dialogShopShow1" :btnShow="false" :shangxiajia="false" @close="close" @res="res"
      :idsList="idsList" :allId="pageData.data_total" @confirm1="handleShopPickerConfirm1" :is_qiye_flag="false"
      :batch="batchModifyPrice" />

    <!-- 复制 -->
    <x-dialog :proxy="copyDialog">
      <el-form :model="copyValidateForm" :rules="copyRules" ref="copyValidateForm" label-width="115px">
        <br />
        <el-form-item label="礼包名称" prop="gift_name">
          <el-input v-model="copyValidateForm.gift_name" placeholder="请输入礼包名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="前端礼包名称" prop="web_gift_name">
          <el-input v-model="copyValidateForm.web_gift_name" placeholder="请输入前端礼包名称" clearable></el-input>
        </el-form-item>
        <br />
      </el-form>
    </x-dialog>

    <!-- 礼包备注 -->
    <x-dialog :proxy="remarkDialog">
      <el-form :model="remarkValidateForm" ref="remarkValidateForm" label-width="80px">
        <br />
        <el-form-item label="备注信息">
          <el-input v-model="remarkValidateForm.remark" type="textarea" show-word-limit :rows="5" resize="none"
            placeholder="请输入不超100字的备注信息" :maxlength="100" clearable></el-input>
        </el-form-item>
        <br />
      </el-form>
    </x-dialog>


    <!-- 礼包种类 -->
    <x-dialog :proxy="kindDialog">
      <div class="kind-list">
        <el-table :data="kindList" border height="468" style="width: 100%" header-align="center">
          <el-table-column label="商品图片" width="120" algin="center">
            <template slot-scope="{row}">
              <el-popover placement="right" trigger="hover">
                <img class="key-cover" :src="row.thumbnail" alt="">
                <img :src="row.thumbnail" class="kind-img" slot="reference" />
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="前端展示图片" width="120" algin="center">
            <template slot-scope="{row}">
              <el-popover placement="right" trigger="hover">
                <img class="key-cover" :src="row.web_thumbnail || row.thumbnail" alt="">
                <img :src="row.web_thumbnail || row.thumbnail" class="kind-img" slot="reference" />
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="商品名称" algin="left" prop="goods_name" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="前端名称" algin="left" prop="goods_alias" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="商品来源" algin="center" width="120">
            <template slot-scope="{row}">
              <template v-if="row.goods_source === 0 && row.self_operated === 0">平台商品</template>
              <template v-if="row.goods_source === 0 && row.self_operated === 1">自有商品</template>
              <template v-if="row.goods_source === 1 && row.self_operated === 0">京东商品</template>
            </template>
          </el-table-column>
          <el-table-column algin="center" label="所属供应商数" v-if="$store.getters.isSupplierName">
            <template slot-scope="{row}">
              {{ row.supplier_name || row.seller_name }}
            </template>
          </el-table-column>
          <el-table-column algin="center" label="数量" width="80">
            <template slot-scope="{row}">
              {{ row.num || 0 }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </x-dialog>
    <!-- 商品详情弹窗 -->
    <el-dialog custom-class="goodsclassify" :visible.sync="dialogVisible" :close-on-click-modal="false"
      :close-on-press-escape="false" append-to-body width="375px">
      <div slot="title" class="goods-picker-title">
        <span></span>
        商品详情
      </div>
      <!-- <goodsclassify v-if="dialogVisible" :formData="goodsclassifyData"></goodsclassify> -->
      <goods-detail v-if="dialogVisible" :formData="goodsclassifyData"></goods-detail>
    </el-dialog>
    <import-goods ref="importGoods" @downloadError="downloadError" @imported="handlePageCurrentChange(1)" />

    <!-- 批量设置自提时间 -->
    <settingTimes :show.sync="settingTimesVisible" :goodsIds="goodsIds" @close="() => settingTimesVisible = false">
    </settingTimes>

    <el-dialog-x-dialog width="750px" title="导出PPT方案" :visible.sync="exportPPTVisible">
      <el-form class="exportPPTForm" ref="exportPPTForm" :model="exportPPTForm" :rules="exportPPTFormRule"
        label-width="130px">
        <el-form-item style="margin-bottom:10px" label="套餐方案名称" prop="package_name">
          <el-input style="width:250px" v-model.trim="exportPPTForm.package_name" :maxlength="20" :minlength="1"
            placeholder="请输入套餐方案名称"></el-input>
        </el-form-item>
        <el-form-item style="margin:0" label="套餐礼包价格展示">
          <el-radio-group v-model="exportPPTForm.package_gift_price">
            <el-radio :label="0">市场总价</el-radio>
            <el-radio :label="1">销售总价</el-radio>
            <el-radio :label="2">分销总价</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin:0" label="套餐单品市场价">
          <el-radio-group v-model="exportPPTForm.package_goods_price">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin:0" label="PPT页面图片显示">
          <el-radio-group v-model="exportPPTForm.package_goods_img">
            <el-radio :label="0">单品图片</el-radio>
            <el-radio :label="1">套餐礼包图片</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin:0" label="导出套餐分类页面">
          <el-radio-group @input="giftCategoryChange" v-model="exportPPTForm.gift_category">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="margin:0" label="PPT背景图设置">
        </el-form-item>
        <div style="display:flex;">
          <el-form-item label="首页封面图">
            <el-upload class="site-logo" accept=".jpeg, .png, .jpg" :action="MixinUploadApi" :show-file-list="false"
              :on-success="(res) => { exportPPTForm.gift_home_img = res.url }" :multiple="false">
              <div v-if="exportPPTForm.gift_home_img">
                <img :src="exportPPTForm.gift_home_img" class="site-logo-img" />
                <i @click="exportPPTForm.gift_home_img = ''" class="el-icon-circle-close deleteImg"></i>
              </div>
              <i v-else class="el-icon-plus logo-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item v-if="exportPPTForm.gift_category == 1" label-width="90px" label="套餐分类图">
            <el-upload class="site-logo" accept=".jpeg, .png, .jpg" :action="MixinUploadApi" :show-file-list="false"
              :on-success="(res) => { exportPPTForm.gift_category_img = res.url }" :multiple="false">
              <div v-if="exportPPTForm.gift_category_img">
                <img :src="exportPPTForm.gift_category_img" class="site-logo-img" />
                <i @click="exportPPTForm.gift_category_img = ''" class="el-icon-circle-close deleteImg"></i>
              </div>
              <i v-else class="el-icon-plus logo-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label-width="120px" label="普通页面背景图">
            <el-upload class="site-logo" accept=".jpeg, .png, .jpg" :action="MixinUploadApi" :show-file-list="false"
              :on-success="(res) => { exportPPTForm.gift_background_img = res.url }" :multiple="false">
              <div v-if="exportPPTForm.gift_background_img">
                <img :src="exportPPTForm.gift_background_img" class="site-logo-img" />
                <i @click="exportPPTForm.gift_background_img = ''" class="el-icon-circle-close deleteImg"></i>
              </div>
              <i v-else class="el-icon-plus logo-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </div>
        <p style="padding-left:140px">提示：请上传1张大小不超10MB，格式为jpg、png、jpeg的图片。建议尺寸：1200*764。</p>
        <el-form-item style="text-align:right">
          <el-button @click="exportPPTVisible = false">取 消</el-button>
          <el-button type="primary" @click="exportPPTConfirm">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import * as API_goods from "@/api/goods";
import * as API_Combo from "@/api/combo-card-shop/combo2";
import { getShopExtAuth } from "@/api/order"
import { CategoryPicker } from "@/components";
import { RegExp, Foundation } from "@/../ui-utils";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import { $goodsCategory } from "@/pages/goods/services/goods-category";
import * as API_Login from "@/api/login";
import { mergesImgDownload } from "@/utils";
import goodsclassify from "@/components/GoodsClassify/goodsclassify";
import goodsDetail from "@/components/GoodsDetail/GoodsDetail";
import statusErr from "@/assets/goods/statusErr.png";
import { getSellerShopExtAuth } from '@/api/account';
import importGoods from "./components/importGoods"
import settingTimes from "./components/settingTimes"
import { getCnpcShopIds } from "@/api/account"
import {
  getJDProvince,
  getJDCity,
  getJDCounty,
  getJDTown,
} from "../../api/goods";
import * as API_Floor from "@/api/floor";

import * as API_gift from "@/api/giftGoods";
import editprice_icon from "@/assets/button_icon/editprice.png";//改价
import up_icon from "@/assets/button_icon/up.png";//上架
import down_icon from "@/assets/button_icon/down.png";//下架
import remove_icon from "@/assets/button_icon/remove.png";//移除
import copy_icon from "@/assets/button_icon/copy.png";//复制
import edit_icon from "@/assets/button_icon/edit.png";//编辑
import enable_icon from "@/assets/button_icon/enable.png";//启用
import disabled_icon from "@/assets/button_icon/disabled.png";//禁用
import delete_icon from "@/assets/button_icon/delete.png";//删除
// import TagZY from '@/assets/goods/tag-zy.png';
// import TagJD from '@/assets/goods/tag-jd.png';
// import TagJX from '@/assets/goods/tag-jx.png';
import { $giftsCategory } from '@/pages/goods/services/gifts-category'
export default {
  name: "shopGoodsList",
  components: {
    XDialog,
    EnTableLayout,
    CategoryPicker,
    goodsclassify,
    goodsDetail,
    importGoods,
    settingTimes,
  },
  data () {
    const checkQuantity = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error("库存不能为空"));
      }
      setTimeout(() => {
        if (!/^[0-9]\d*$/.test(value)) {
          callback(new Error("请输入整数"));
        } else if (!(parseInt(value) >= 0 && parseInt(value) <= 99999999)) {
          callback(new Error("请输入0 - 99999999之间的正整数"));
        } else {
          callback();
        }
      }, 500);
    };
    const checkMoney = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("返利金额不能为空"));
      }
      setTimeout(() => {
        if (!RegExp.money.test(value)) {
          callback(new Error("请输入正确的金额"));
        } else if (parseFloat(value) < 0 || parseFloat(value) > 99999999) {
          callback(new Error("请输入0~99999999之间的金额"));
        } else {
          callback();
        }
      }, 500);
    };

    return {
      settingTimesVisible: false,//批量设置自提时间
      publishOnlineDialog: $xDialog.create({
        title: "导入操作",
        width: "70vw",
        afterDismiss: () => {
          this.tooltipContentTwo = ''
          this.cardMemberVO = {//表格导入发放名单内容
            phone_list: {}
          }
          this.dialogVisible = false
        },
        beforeConfirm: () => {
          return new Promise(resolve => {
            this.submitCreateGrant1("activationRef")
          });
        }
      }),
      exportPPTVisible: false,
      exportPPTForm: {
        package_name: '', // 套餐方案名称
        package_gift_price: 0, // 0市场总价 1销售总价 2分销总价
        package_goods_price: 1, // 套餐单品市场价展示：0:不显示 1:显示
        package_goods_img: 0, // PPT页面图片展示：0:单品图片 1:套餐礼包图片
        gift_category: 0, // 导出套餐分类页面：0:否 1:是
        gift_home_img: '', // 首页封面图
        gift_category_img: '', // 套餐分类图
        gift_background_img: '' // 普通页面背景图
      },
      exportPPTFormRule: {
        package_name: [
          { required: true, message: '请填写套餐方案名称', trigger: 'blur' }
        ]
      },
      editprice_icon,
      down_icon,
      up_icon,
      remove_icon,
      copy_icon,
      edit_icon,
      enable_icon,
      disabled_icon,
      delete_icon,
      merges: [],
      inportVisible: false, //导入错误提示弹窗
      dialogVisible: false,//商品详情弹窗
      goodsclassifyData: {},//商品详情传参
      force_exchange_goods_open: false,
      waitSold: false, // 是否可以单独售卖
      waitExchange: false, // 是否可以智能换货
      waitSoldTimer: null,
      waitExchangeTimer: null,
      restrictNum: null,
      keyword: "",
      key_word: "goods_name",
      modifyPriceDialogRef: $xDialog.create({
        width: "600px",
        afterDismiss: () => this.revisePriceClosed(),
        beforeConfirm: () => this.revisePrice(),
      }),
      kindList: [],
      kindDialog: $xDialog.create({
        title: "商品展示",
        width: "1024px",
        disableCancel: true,
        disableConfirm: true,
      }),
      areaNum: 1,
      provinceList: [],
      cityList: [],
      countyList: [],
      townList: [],
      areaSelectData: {
        province: "",
        city: "",
        county: "",
        town: "",
        jd_area_state: 0,
      },
      history: {
        remark: "",
      },
      shopTypeList: [
        {
          label: "精选优品",
          value: 0,
        },
        {
          label: "自有商品",
          value: 1,
        },
        {
          label: "京东商品",
          value: 2,
        },
        {
          label: "礼包列表",
          value: 3,
        },
      ],
      activeName: "1",
      dataForm: {},
      /** 列表loading状态 */
      loading: false,
      isRevisePrice: false,
      revisePriceTitle: "",
      options: [
        {
          label: "分销价",
          value: 1,
        },
        {
          label: "市场价",
          value: 2,
        },
      ],
      options1: [
        {
          label: "上调",
          value: "UP",
        },
        {
          label: "下降",
          value: "DOWN",
        },
        {
          label: "等于",
          value: "EQUAL",
        },
      ],
      revisePriceList: [],
      revisePriceForm: {
        price_type: 1,
        symbol: "UP",
        ratio: 0,
      },
      cou: 1,
      idsList: [],
      /** 商品选择器最大长度*/
      maxsize: 0,

      showDialog: false,
      /** 商品选择器列表api*/
      goodsApi: "seller/goods/list-all",

      multipleApi: "seller/goods/@ids/details",

      /** 商城分类api */
      categoryApi: "seller/goods/category/@id/children-all",

      /** 商品ids */
      goodsIds: [],

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        self_goods: "1",
        low_enterprice: "",
        high_enterprice: "",
        category_path: "",
        keyword: "",
        sold_separately_enable: '',
        sort: "",
        goods_name: "",
        goods_sn: "",
        has_goods: 0,
        high_mktprice: "",
        low_mktprice: "",
        shop_goods_status: "",
        pre: "",
        sign: "",
        suf: "",
        // 毛利率
        profit_min: "",
        profit_max: "",
        // 利润率
        shop_profit_min: "",
        shop_profit_max: "",
        supplier_enable: 1,
        market_enable: 1,
      },
      /** 列表数据 */
      tableData: {},

      /** 列表分页数据 */
      pageData: {},

      /** 商品状态列表 */
      goodsStatusList: [
        {
          value: 0,
          label: "已下架",
        },
        {
          value: 1,
          label: "已上架",
        },
      ],

      /** 商品类型 NORMAL 正常商品 POINT 积分商品 */
      goods_type: "",

      /** 当前商品分组*/
      categoryId: "",

      /** 当前商品id*/
      goodsId: "",

      /** 商品库存显示*/
      goodsStockshow: false,

      /** 是否显示下架原因 */
      isShowUnderReason: false,

      /** 下架原因 */
      under_reason: "",

      /** 库存商品数量*/
      goodsStocknums: 0,

      /** 商品库存列表数据*/
      goodsStockData: null,

      /** 商品库存列表表头数据 */
      goodsStockTitle: [],

      /** 要合并的列的位置数组 */
      concactArray: [],

      /** 校验规则 */
      rules: {
        quantity: [
          {
            validator: checkQuantity,
            trigger: "blur",
          },
        ],
      },

      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,

      /** 分销设置是否开启 1开启 0关闭 */
      distributionSet: 0,

      /** 是否显示分销返利弹框 */
      isShowDisRebate: false,
      importLoading: false,
      /** 分销返利数据 */
      disRebateData: {
        /** 商品id */
        goods_id: 0,

        /** 1级返利 */
        grade1Rebate: 0,

        /** 2级返利 */
        grade2Rebate: 0,
      },
      batchModifyPrice: false,
      /** 分销返利校验规则 */
      disRules: {
        grade1Rebate: [
          {
            required: true,
            message: "1级返利金额不能为空",
            trigger: "blur",
          },
          {
            validator: checkMoney,
            trigger: "blur",
          },
        ],
        grade2Rebate: [
          {
            required: true,
            message: "2级返利金额不能为空",
            trigger: "blur",
          },
          {
            validator: checkMoney,
            trigger: "blur",
          },
        ],
      },
      list1: [],
      list2: [
        {
          label: "大于",
          value: "gt",
        },
        {
          label: "等于",
          value: "eq",
        },
        {
          label: "小于",
          value: "lt",
        },
      ],
      list3: [
        {
          label: "市场价",
          value: "mkt",
        },
        {
          label: "分销价",
          value: "dis",
        },
        {
          label: "销售价",
          value: "sale",
        },
      ],
      dialogShopShow1: false,
      pa: {},
      hasReviseTask: true, // 后台是否有正在进行中的任务，如果有为'OK'，此时不能进行改价,否则无，可以改价
      reviseTaskTimer: null,
      firstCategory: [],
      secondCategory: [],
      threeCategory: [],
      is_qiye_flag: false,
      categoryParams: "",
      categoryProxy: $goodsCategory.create(),
      giftCategoryParams: '',
      JDCategory: $giftsCategory.create(),
      quantityStatus: false,
      navList: [],
      activeIndex: null,
      gift: {
        labelNamesArea: "",
        comboType: "",
        low_price: undefined,
        high_price: undefined,
        lowDistributionProfit: undefined,
        highDistributionProfit: undefined,
        lowPriceProfit: undefined,
        highPriceProfit: undefined,
        lowMarketProfit: undefined,
        highMarketProfit: undefined,
        company: "",
        normalStatus: void 0,
        lowEnterPrice: undefined,
        highEnterPrice: undefined,
        highMktPrice: undefined,
        lowMktPrice: undefined,
        key_word: "gift_name",
        keyword: "",
        time_range: ["", ""],
        create_time_start: "",
        create_time_end: "",
        goodsExchange: "",
        comboStatus: "",
        multipleSelection: [],
        sort: "",
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now();
        },
      },
      copyValidateForm: {
        id: "",
        gift_name: "",
        web_gift_name: "",
      },
      copyRules: {
        gift_name: [
          {
            required: true,
            message: "请输入礼包名称",
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 32,
            message: "当前名称已超出字数上限，请修改礼包名称",
            trigger: ["change", "blur"],
          },
        ],
        web_gift_name: [
          {
            required: true,
            message: "请输入礼包前端名称",
            trigger: ["change", "blur"],
          },
          {
            min: 0,
            max: 32,
            message: "当前名称已超出字数上限，请修改礼包前端名称",
            trigger: ["change", "blur"],
          },
        ],
      },
      copyDialog: $xDialog.create({
        width: "500px",
        beforeConfirm: () => this.submitCopyGift(),
      }),
      remarkValidateForm: {
        id: "",
        remark: "",
      },
      remarkDialog: $xDialog.create({
        width: "500px",
        beforeConfirm: () => this.submitGiftRemark(),
      }),
      ispetroChina: false,//是否是中石油商城
      isGlzcShop: false,
      exportType: 5,
    };
  },
  watch: {
    "areaSelectData.province": {
      handler (newValue) {
        this.areaSelectData.city = "";
        this.areaSelectData.county = "";
        this.areaSelectData.town = "";
        if (newValue === "") {
          this.areaNum = 1;
          this.getAreaHandle("province");
        } else {
          this.areaNum = 2;
          this.getAreaHandle("city", { id: newValue });
        }
      },
      immediate: true,
      deep: true,
    },
    "areaSelectData.city": {
      handler (newValue) {
        this.areaSelectData.county = "";
        this.areaSelectData.town = "";
        if (this.areaSelectData.province === "") return;
        if (newValue === "") this.areaNum = 2;
        else {
          this.areaNum = 3;
          this.getAreaHandle("county", { id: newValue });
        }
      },
      deep: true,
    },
    "areaSelectData.county": {
      handler (newValue) {
        this.areaSelectData.town = "";
        if (this.areaSelectData.city === "") return;
        if (newValue === "") this.areaNum = 3;
        else {
          this.areaNum = 4;
          this.getAreaHandle("town", { id: newValue });
        }
      },
      deep: true,
    },
  },
  filters: {
    /** 销售状态格式化 */
    marketStatus (row) {
      switch (row.shop_goods_status) {
        case 0:
          return "已下架";
        case 1:
          return "已上架";
      }
    },

    marketEnable (row) {
      return row.market_enable === 1 ? "上架" : "下架";
    },
    authStatus (row) {
      if (row.is_auth === 0) {
        return "待审核";
      } else if (row.is_auth === 1) {
        return "审核通过";
      } else if (row.is_auth === 2) {
        return "审核拒绝";
      }
    },
  },
  async created () {
    let shopidList = await getCnpcShopIds()
    shopidList = shopidList.data.split(',')
    this.ispetroChina = shopidList.includes((this.$store.getters.shopInfo.shop_id).toString())
    switch (sessionStorage.getItem("shopGoodsListType")) {
      case "1":
        this.params.skip = 1;
        break;
      case "2":
        this.params.skip = 2;
        break;
      case "3":
        // this.params.skip = 3;
        this.params.sold_separately_enable = 0;
        break;
      case "4":
        // this.params.skip = 4;
        this.params.sold_separately_enable = 1;
        break;
      default:
        break;
    }

    if (sessionStorage.getItem("MixinIsFormEnterprise")) {
      this.MixinIsFormEnterprise = JSON.parse(
        sessionStorage.getItem("MixinIsFormEnterprise")
      );
    }
    this.getNavList();
    API_Login.getLoginType().then((res) => {
      if (res && res.parentLogin === 1) {
        this.MixinIsFormEnterprise = true;
        sessionStorage.setItem("MixinIsFormEnterprise", true);
      } else sessionStorage.setItem("MixinIsFormEnterprise", false);
    });
    this.list1 = [
      {
        label: "市场价",
        value: "mkt",
      },
      {
        label: "分销价",
        value: "dis",
      },
      {
        label: "销售价",
        value: "sale",
      },
    ];
    if (!this.MixinIsFormEnterprise && this.ispetroChina) {//单独登录并且是中石油的 隐藏分销价
      this.options.forEach((item, indexs) => {
        if (item.label == '分销价') this.options.splice(indexs, 1)
      })
      this.revisePriceForm.price_type = 2
      this.list1.forEach((item, indexs) => {
        if (item.value == 'dis') this.list1.splice(indexs, 1)
      })
      this.list3.forEach((item, indexs) => {
        if (item.value == 'dis') this.list3.splice(indexs, 1)
      })
    }
    if (this.MixinIsFormEnterprise) {
      this.list1.push({
        label: "会员价",
        value: "mem",
      });
    }
    if (this.MixinIsFormEnterprise) {
      this.list3.push({
        label: "会员价",
        value: "mem",
      });
    }
  },
  activated () {
    console.log(this.$route.query.activeName, 'activated');
    this.GET_GoodsList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  async mounted () {
    this.getShopExtAuth();
    this.GET_GoodsList();
    this.getDistributionSet();
    this.loadReviseTask();
    this.checkWaitSold();
    this.checkWaitExchange();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
    let shopidList = await API_goods.getExportShopIds()
    shopidList = shopidList.data.split(',')
    this.isGlzcShop = shopidList.includes((this.$store.state.user.shop.parent_id).toString())
    if (this.MixinIsFormEnterprise) {//是企业跳转过来
      if (this.$store.getters.isSupplierName && this.isGlzcShop) {//有供应商并且是国联智诚
        this.exportType = 1
      } else {
        if (this.$store.getters.isSupplierName && !this.isGlzcShop) {
          this.exportType = 2
        } else {
          if (!this.$store.getters.isSupplierName && this.isGlzcShop) {
            this.exportType = 3
          } else {//都没有
            this.exportType = 4
          }
        }
      }
    } else {
      this.exportType = 5
    }
  },
  destroyed () {
    this.reviseTaskTimer && clearInterval(this.reviseTaskTimer);
    this.waitSoldTimer && clearTimeout(this.waitSoldTimer)
    this.waitExchangeTimer && clearTimeout(this.waitExchangeTimer)
  },
  methods: {
    // 表头部重新渲染
    renderHeader (h, { column, $index }) {
      setTimeout(() => {
        // 获取操作按钮组的元素
        const opts = document.getElementsByClassName('operate');
        let widthArr = [];
        // 取操作组的最大宽度
        Array.prototype.forEach.call(opts, function (item) {
          if (item.innerText || item.children) {
            widthArr.push(item.offsetWidth);
          }
        });
        // 重新设置列标题及宽度属性
        if (widthArr.length > 0) {
          column.width = Math.max(...widthArr) + 24;
        } else {
          column.width = 80;
        }
      }, 0)
      return h('span', '操作')
    },
    getRowTagImage (row) {
      if (row.normal_status === 0) return statusErr
    },
    batchOps (ops) {//批量操作
      if (!this.waitExchange) {//判断是否禁用      
        switch (ops) {
          case 'open':
            return this.openGoodsExchange();
          case 'close':
            return this.closeGoodsExchange();
        }
      }
    },
    goodsbatchOps (ops) {//批量操作
      switch (ops) {
        case 'modify':
          return this.handleBatchCommand("modify");
        case 'on':
          return this.handleBatchCommand("on");
        case 'off':
          return this.handleBatchCommand("off");
        case 'remove':
          return this.handleBatchCommand("remove");
        case 'openGoodsSale':
          return this.openGoodsSale();
        case 'closeGoodsSale':
          return this.closeGoodsSale();
        case 'settingTime':
          return this.settingTime();
      }
    },
    exportbatchOps (ops) {//导出PPT
      switch (ops) {
        case 'exportPPT':
          return this.importMore();
        case 'twoExportPPT':
          return this.importMoreTwo();
        case 'exportExcel':
          return this.exportMore();
      }
    },

    //站点订单列表导出PPT方法
    importMore () {
      if (this.gift.multipleSelection.length == 0) {
        this.$message.error("请勾选礼包");
        return;
      }
      const list = this.gift.multipleSelection.map(item => item.id)
      API_goods.getImportRecordList({ combo_ids: list, page_no: 1, page_size: 9999 }).then(res => {
        let a = document.createElement('a');//创建a标签
        a.href = res;//文件url
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();//触发下载
        document.body.removeChild(a);
        this.$message.success('导出成功')
      })
    },
    giftCategoryChange (val) {
      if (val == 0) {
        this.exportPPTForm.gift_category_img = ''
      }
    },
    //导出PPT方案2
    importMoreTwo () {
      if (this.gift.multipleSelection.length == 0) {
        this.$message.error("请勾选礼包");
        return;
      }
      this.exportPPTVisible = true
    },
    resetPPTForm () {//重置表单
      this.exportPPTForm = {
        package_name: '', // 套餐方案名称
        package_gift_price: 0, // 0市场总价 1销售总价 2分销总价
        package_goods_price: 1, // 套餐单品市场价展示：0:不显示 1:显示
        package_goods_img: 0, // PPT页面图片展示：0:单品图片 1:套餐礼包图片
        gift_category: 0, // 导出套餐分类页面：0:否 1:是
        gift_home_img: '', // 首页封面图
        gift_category_img: '', // 套餐分类图
        gift_background_img: '' // 普通页面背景图
      }
    },
    exportPPTConfirm () {
      this.$refs['exportPPTForm'].validate(valid => {
        if (valid) {
          const list = this.gift.multipleSelection.map(item => item.id)
          console.log(list);
          const dataform = Object.fromEntries(Object.entries(this.exportPPTForm).filter(([key, value]) => value || value === 0))
          API_goods.newExportComboList({ ...dataform, combo_ids: list }).then(res => {
            let a = document.createElement('a');//创建a标签
            a.href = res;//文件url
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();//触发下载
            document.body.removeChild(a);
            this.$message.success('导出成功')
            this.exportPPTVisible = false
            this.resetPPTForm()
          })
        }
      })
    },
    //站点订单列表导出Excel方法
    exportMore () {
      if (this.gift.multipleSelection.length == 0) {
        this.$message.error("请勾选礼包");
        return;
      }
      this.importLoading = true;
      let exportExcel = {
        type: this.exportType,//类型
        gift_list: []//礼包集合
      }
      this.gift.multipleSelection.map(item => {
        let obj = {//格式化单个礼包数值
          goods_list: [],//商品集合
          sub_totals: []//合计
        };
        if (this.exportType == 1) {//企业挑战点  都有供应商和大平台成本价
          let all_admin_cost = 0 //大后台（成本价）合计
          item.goods_volist.map(els => {

            //判断是否是京东商品
            let jdUrl = '';//京东链接
            let goods_void = els.shop_goods_vo.goods_do.sn;//商品编码
            let adminCost = els.shop_goods_vo.goods_do.cost
            //自有商品
            if (els.shop_goods_vo.goods_do.goods_source == 0 && els.shop_goods_vo.goods_do.self_operated === 1) {
              adminCost = ''
            }
            if (els.supplier_name == '京东供应商') {
              jdUrl = `https://item.jd.com/${els.shop_goods_vo.goods_do.ext_goods_id}.html`;//京东链接
              goods_void = els.shop_goods_vo.goods_do.ext_goods_id;//商品编码
            }
            if (adminCost) {
              all_admin_cost = this.addPrice(all_admin_cost, this.accMul(adminCost, els.num))
            }
            let arrays = [
              item.id,//编号
              item.category_name,//套餐分类
              item.label_names_gift,//标签
              els.goods_name,//商品名称
              els.num,//数量
              "",//图片占位（空）
              "",//图片占位（空）
              "",//图片占位（空）
              "",//参数（空）
              "",//产品说明（空）
              els.shop_goods_vo.goods_do.mktprice,//市场价
              els.shop_goods_vo.revise_price,//销售价
              item.shop_sum_price,//套餐价
              jdUrl,//京东链接
              '',//报价截止时间（空）
              goods_void,//商品编码
              els.shop_goods_vo.enterprise_revise_price,//分销价
              '',//分销利润率
              '',//成本（空）
              adminCost,//成本价（大后台）
              '',//运费（空）
              '',//仓储成本（空）
              '',//销售提成（空）
              '',//卡券成本（空）
              ['已下架', '已上架'][els.shop_goods_vo.shop_goods_status],//商品状态
              els.supplier_name,//供应商名称
              '',//成本最终（空）
              '',//最终毛利率（空）
              '',//采购提成（空）
              item.remark,//备注
              els.shop_goods_vo.goods_do.original,//商品图片
            ]
            obj.goods_list.push(arrays)
          })
          obj.sub_totals = [
            '',
            '',
            '',
            '合计',
            '',
            '',
            '',
            '',
            '',
            '',
            item.market_sum_price,//礼包市场总价
            item.shop_sum_price,//礼包销售总价
            item.shop_sum_price,//礼包销售总价
            '',
            '',
            '',
            item.enterprise_sum_price,//礼包分销总价
            item.distribution_profit ? item.distribution_profit.toFixed(2) : 0,//分销利润率,//礼包分销利润率
            '',
            all_admin_cost || '',//成本价（大后台）
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',//商品图片占位
          ]
        } else if (this.exportType == 2) {//企业挑战点  没有大平台成本价
          item.goods_volist.map(els => {
            //判断是否是京东商品
            let jdUrl = '';//京东链接
            let goods_void = els.shop_goods_vo.goods_do.sn;//商品编码
            if (els.supplier_name == '京东供应商') {
              jdUrl = `https://item.jd.com/${els.shop_goods_vo.goods_do.ext_goods_id}.html`;//京东链接
              goods_void = els.shop_goods_vo.goods_do.ext_goods_id;//商品编码
            }
            let arrays = [
              item.id,//编号
              item.category_name,//套餐分类
              item.label_names_gift,//标签
              els.goods_name,//商品名称
              els.num,//数量
              "",//图片占位（空）
              "",//图片占位（空）
              "",//图片占位（空）
              "",//参数（空）
              "",//产品说明（空）
              els.shop_goods_vo.goods_do.mktprice,//市场价
              els.shop_goods_vo.revise_price,//销售价
              item.shop_sum_price,//套餐价
              jdUrl,//京东链接
              '',//报价截止时间（空）
              goods_void,//商品编码
              els.shop_goods_vo.enterprise_revise_price,//分销价
              '',//分销利润率
              '',//成本（空）

              '',//运费（空）
              '',//仓储成本（空）
              '',//销售提成（空）
              '',//卡券成本（空）
              ['已下架', '已上架'][els.shop_goods_vo.shop_goods_status],//商品状态
              els.supplier_name,//供应商名称
              '',//成本最终（空）
              '',//最终毛利率（空）
              '',//采购提成（空）
              item.remark,//备注
              els.shop_goods_vo.goods_do.original,//商品图片
            ]
            obj.goods_list.push(arrays)
          })
          obj.sub_totals = [
            '',
            '',
            '',
            '合计',
            '',
            '',
            '',
            '',
            '',
            '',
            item.market_sum_price,//礼包市场总价
            item.shop_sum_price,//礼包销售总价
            item.shop_sum_price,//礼包销售总价
            '',
            '',
            '',
            item.enterprise_sum_price,//礼包分销总价
            item.distribution_profit ? item.distribution_profit.toFixed(2) : 0,//分销利润率,//礼包分销利润率
            '',

            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',//商品图片占位
          ]
        } else if (this.exportType == 3) {//企业跳站点  没有供应商名称
          let all_admin_cost = 0 //大后台（成本价）合计
          item.goods_volist.map(els => {
            //判断是否是京东商品
            let jdUrl = '';//京东链接
            let goods_void = els.shop_goods_vo.goods_do.sn;//商品编码
            let adminCost = els.shop_goods_vo.goods_do.cost
            //自有商品
            if (els.shop_goods_vo.goods_do.goods_source == 0 && els.shop_goods_vo.goods_do.self_operated === 1) {
              adminCost = ''
            }
            if (els.supplier_name == '京东供应商') {
              jdUrl = `https://item.jd.com/${els.shop_goods_vo.goods_do.ext_goods_id}.html`;//京东链接
              goods_void = els.shop_goods_vo.goods_do.ext_goods_id;//商品编码
            }
            if (adminCost) {
              all_admin_cost = this.addPrice(all_admin_cost, this.accMul(adminCost, els.num))
            }
            let arrays = [
              item.id,//编号
              item.category_name,//套餐分类
              item.label_names_gift,//标签
              els.goods_name,//商品名称
              els.num,//数量
              "",//图片占位（空）
              "",//图片占位（空）
              "",//图片占位（空）
              "",//参数（空）
              "",//产品说明（空）
              els.shop_goods_vo.goods_do.mktprice,//市场价
              els.shop_goods_vo.revise_price,//销售价
              item.shop_sum_price,//套餐价
              jdUrl,//京东链接
              '',//报价截止时间（空）
              goods_void,//商品编码
              els.shop_goods_vo.enterprise_revise_price,//分销价
              '',//分销利润率
              '',//成本（空）
              adminCost,//成本价（大后台）
              '',//运费（空）
              '',//仓储成本（空）
              '',//销售提成（空）
              '',//卡券成本（空）
              ['已下架', '已上架'][els.shop_goods_vo.shop_goods_status],//商品状态

              '',//成本最终（空）
              '',//最终毛利率（空）
              '',//采购提成（空）
              item.remark,//备注
              els.shop_goods_vo.goods_do.original,//商品图片
            ]
            obj.goods_list.push(arrays)
          })
          obj.sub_totals = [
            '',
            '',
            '',
            '合计',
            '',
            '',
            '',
            '',
            '',
            '',
            item.market_sum_price,//礼包市场总价
            item.shop_sum_price,//礼包销售总价
            item.shop_sum_price,//礼包销售总价
            '',
            '',
            '',
            item.enterprise_sum_price,//礼包分销总价
            item.distribution_profit ? item.distribution_profit.toFixed(2) : 0,//分销利润率,//礼包分销利润率
            '',
            all_admin_cost || '',//成本价（大后台）
            '',
            '',
            '',
            '',
            '',

            '',
            '',
            '',
            '',
            '',//商品图片占位
          ]
        } else if (this.exportType == 4) {//企业跳站点  都没有供应商和大平台成本价
          item.goods_volist.map(els => {
            //判断是否是京东商品
            let jdUrl = '';//京东链接
            let goods_void = els.shop_goods_vo.goods_do.sn;//商品编码
            if (els.supplier_name == '京东供应商') {
              jdUrl = `https://item.jd.com/${els.shop_goods_vo.goods_do.ext_goods_id}.html`;//京东链接
              goods_void = els.shop_goods_vo.goods_do.ext_goods_id;//商品编码
            }
            let arrays = [
              item.id,//编号
              item.category_name,//套餐分类
              item.label_names_gift,//标签
              els.goods_name,//商品名称
              els.num,//数量
              "",//图片占位（空）
              "",//图片占位（空）
              "",//图片占位（空）
              "",//参数（空）
              "",//产品说明（空）
              els.shop_goods_vo.goods_do.mktprice,//市场价
              els.shop_goods_vo.revise_price,//销售价
              item.shop_sum_price,//套餐价
              jdUrl,//京东链接
              '',//报价截止时间（空）
              goods_void,//商品编码
              els.shop_goods_vo.enterprise_revise_price,//分销价
              '',//分销利润率
              '',//成本（空）

              '',//运费（空）
              '',//仓储成本（空）
              '',//销售提成（空）
              '',//卡券成本（空）
              ['已下架', '已上架'][els.shop_goods_vo.shop_goods_status],//商品状态

              '',//成本最终（空）
              '',//最终毛利率（空）
              '',//采购提成（空）
              item.remark,//备注
              els.shop_goods_vo.goods_do.original,//商品图片
            ]
            obj.goods_list.push(arrays)
          })
          obj.sub_totals = [
            '',
            '',
            '',
            '合计',
            '',//数量
            '',//图片
            '',//图片
            '',//图片
            '',
            '',
            item.market_sum_price,//礼包市场总价
            item.shop_sum_price,//礼包销售总价
            item.shop_sum_price,//礼包销售总价
            '',
            '',
            '',
            item.enterprise_sum_price,//礼包分销总价
            item.distribution_profit ? item.distribution_profit.toFixed(2) : 0,//分销利润率,//礼包分销利润率
            '',

            '',
            '',
            '',
            '',
            '',

            '',
            '',
            '',
            '',
            '',//商品图片占位
          ]
        } else {//单独登录
          item.goods_volist.map(els => {
            //判断是否是京东商品
            let jdUrl = '';//京东链接
            let goods_void = els.shop_goods_vo.goods_do.sn;//商品编码
            if (els.supplier_name == '京东供应商') {
              jdUrl = `https://item.jd.com/${els.shop_goods_vo.goods_do.ext_goods_id}.html`;//京东链接
              goods_void = els.shop_goods_vo.goods_do.ext_goods_id;//商品编码
            }
            let arrays = [
              item.id,//编号
              item.category_name,//套餐分类
              item.label_names_gift,//标签
              els.goods_name,//商品名称
              els.num,//数量
              "",//图片占位（空）
              "",//图片占位（空）
              "",//图片占位（空）
              "",//参数
              "",//产品说明
              els.shop_goods_vo.goods_do.mktprice,//市场价
              els.shop_goods_vo.revise_price,//销售价
              item.shop_sum_price,//套餐价
              jdUrl,//京东链接
              '',//报价截止时间
              goods_void,//商品编码
              els.shop_goods_vo.enterprise_revise_price,//分销价
              ['已下架', '已上架'][els.shop_goods_vo.shop_goods_status],//商品状态
              item.remark ? item.remark : '',//备注
              els.shop_goods_vo.goods_do.original,//商品图片
            ]
            obj.goods_list.push(arrays)
          })
          obj.sub_totals = [
            '',
            '',
            '',
            '合计',
            '',
            '',
            '',
            '',
            '',
            '',
            item.market_sum_price,
            item.shop_sum_price,
            item.shop_sum_price,
            '',
            '',
            '',
            item.enterprise_sum_price,
            '',
            '',
            ''
          ]
        }
        exportExcel.gift_list.push(obj)
      })
      API_goods.exportExcelComboList(exportExcel).then(res => {
        let a = document.createElement('a');//创建a标签
        a.href = res;//文件url
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();//触发下载
        document.body.removeChild(a);
        this.$message.success('导出成功')
        this.importLoading = true;
      })
    },
    /**
     * 两位小数加法
     */
    addPrice (a, b) {
      a = a.toString()
      b = b.toString()
      a.split('.')[1] ? a.split('.')[1].length === 1 && (a += '0') : (a += '00')
      b.split('.')[1] ? b.split('.')[1].length === 1 && (b += '0') : (b += '00')
      return (Number(a.replace('.', '')) + Number(b.replace('.', ''))) / 100
    },
    // 一个高精度乘法计算的函数
    accMul (arg1, arg2) {
      let m = 0
      let s1 = arg1.toString()
      let s2 = arg2.toString()
      try {
        m += s1.split('.')[1].length
      } catch (e) { }
      try {
        m += s2.split('.')[1].length
      } catch (e) { }
      return (
        (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
        Math.pow(10, m)
      )
    },
    //获取当前日期函数
    getNowFormatDate () {
      let date = new Date(),
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate() // 获取当前日(1-31)
      if (month < 10) month = `0${month}` // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}` // 如果日是个位数，在前面补0

      return `${year}${month}${strDate}`
    },

    opengoodsclassify (row) {//打开商品详情弹窗
      this.goodsclassifyData = {
        from: "1",
        goods_id: row.goods_id,
        good_sn: row.good_sn,
        revise_price: row.revise_price,
        islogin: this.MixinIsFormEnterprise,//是否单独登录
        shop_profit: row.shop_profit
      }
      this.dialogVisible = true
    },
    genGoodsData (goods_volist) {
      let ret = [];
      goods_volist.forEach((item) => {
        let profit_price = 0;
        let {
          goods_id,
          goods_name,
          supplier_name,
          goods_alias,
          num,
          id,
          seq,
          exchange_goods_num,
          web_thumbnail,
          web_gift_name,
          shop_goods_vo: {
            enterprise_revise_price,
            goods_do: {
              thumbnail,
              ext_goods_id,
              goods_source,
              mktprice,
              price,
              enable_quantity,
              self_operated,
              sn,
            },
            revise_price,
            shop_goods_status,
            disabled, // 值等于0时表示大平台下架商品
          },
        } = item;
        ret.push({
          sn,
          id,
          goods_alias,
          web_gift_name,
          exchange_goods_num,
          web_thumbnail,
          goods_id,
          thumbnail,
          goods_name,
          supplier_name,
          ext_goods_id,
          goods_source,
          revise_price,
          mktprice,
          enterprise_revise_price,
          price,
          num,
          seq,
          enable_quantity,
          shop_goods_status,
          disabled,
          profit_price,
          self_operated,
        });
      });
      return ret;
    },

    showKindDialog (comboId) {
      API_Combo.getCombo(comboId).then((res) => {
        this.kindList = this.genGoodsData([...res.goods_volist]);
        this.kindDialog.display()
      })
    },

    getShopExtAuth () {
      getShopExtAuth().then(res => {
        if (res.force_exchange_goods_open === "CLOSE") {
          this.force_exchange_goods_open = false;
        }
        else {
          this.force_exchange_goods_open = true;
        }
      })
    },

    generateNewName (name) {
      // 定义正则表达式，匹配名字后面的空格和数字
      const pattern = /(\s-\d+)$/;

      if (pattern.test(name)) {
        // 如果名字已经符合要求，提取数字并加1
        // const num = parseInt(name.match(/\d+/)[0]) + 1;
        // const newName = name.replace(pattern, ` -${num}`);
        const newName = `${name} -1`;
        return newName;
      } else {
        // 如果名字不符合要求，附加 -1
        const newName = `${name} -1`;
        return newName;
      }
    },
    // 检查单独售卖修改任务是否结束
    checkWaitSold () {
      this.waitSold = true;
      API_goods.checkSoldSeparately().then((res) => {
        if (res === "OK") {
          this.waitSold = false;
          this.waitSoldTimer && clearTimeout(this.waitSoldTimer);
          if (this.activeName != 3) {
            this.GET_GoodsList();
          }
        } else {
          this.waitSold = true;
          this.waitSoldTimer = setTimeout(() => {
            this.checkWaitSold();
          }, 500);
        }
      });
    },
    // 检查智能换货修改任务是否结束
    checkWaitExchange () {
      this.waitExchange = true;
      API_goods.checkGoodsAiExchange().then((res) => {
        if (res === "OK") {
          this.waitExchange = false;
          this.waitExchangeTimer && clearTimeout(this.waitExchangeTimer);
          if (this.activeName == 3) {
            this.GET_GoodsList();
          }
        } else {
          this.waitExchange = true;
          this.waitExchangeTimer = setTimeout(() => {
            this.checkWaitExchange();
          }, 500);
        }
      });
    },
    getGoodsSource (key = this.activeName) {
      let goods_source;
      switch (+key) {
        case 0:
          goods_source = 0; // 平台方
          break;
        case 1:
          goods_source = 9; // 自有
          break;
        case 2:
          goods_source = 1; // 京东
          break;
        default:
          break;
      }
      return goods_source;
    },
    getListParams () {
      let params = { ...this.params };
      if (this.activeName === "2" && this.areaSelectData.province !== "") {
        let jd_area = "" + this.areaSelectData.province + "_";
        if (this.areaSelectData.city !== "")
          jd_area += this.areaSelectData.city + "_";
        else jd_area += "0_";
        if (this.areaSelectData.county !== "")
          jd_area += this.areaSelectData.county + "_";
        else jd_area += "0_";
        if (this.areaSelectData.town !== "")
          jd_area += this.areaSelectData.town;
        else jd_area += "0";
        params.jd_area = jd_area;
        params.jd_area_state = this.areaSelectData.jd_area_state;
      } else if (params.jd_area) {
        delete params.jd_area;
        delete params.jd_area_state;
      }
      params.is_combo = 0;
      params.supplier_enable = 1;
      return params;
    },
    getGiftListParams () {
      const { page_no, page_size } = this.params;
      const {
        lowEnterPrice,
        highEnterPrice,
        key_word,
        keyword,
        create_time_start,
        create_time_end,
        goodsExchange,
        comboStatus,
        highMktPrice,
        lowMktPrice,
        sort,
      } = this.gift;
      let err = [];
      if (
        !this.isEmpty(lowEnterPrice) &&
        !this.isEmpty(highEnterPrice) &&
        lowEnterPrice > highEnterPrice
      ) {
        err.push("最低分销总价应小于等于最高分销总价");
      }
      if (
        !this.isEmpty(lowMktPrice) &&
        !this.isEmpty(highMktPrice) &&
        lowMktPrice > highMktPrice
      ) {
        err.push("最低套餐价应小于等于最高套餐价");
      }
      if (err.length) {
        for (let i = 0; i < err.length; i++) {
          setTimeout(() => {
            this.$message.error(err[i]);
          }, 300 * i);
        }
        return false;
      }
      return {
        page_no,
        page_size,
        lowEnterPrice,
        highEnterPrice,
        create_time_start,
        create_time_end,
        goodsExchange,
        comboStatus,
        highMktPrice,
        lowMktPrice,
        sort,
      };
    },
    openGoodsSale () {
      // let list = [...this.goodsIds]
      // if (list.length > 0) {
      //   API_goods.changeGoodsSale({
      //     checkedGoodsId: list,
      //     sold_separately: 1,
      //     //goods_source: this.getGoodsSource()
      //   }, 0).then((res) => {
      //     this.$message.success("操作成功");
      //     this.checkWaitSold();
      //   });
      //   return;
      // }
      this.$confirm(`确定要将当前全部商品的【单独售卖】开关开启吗？`, "提示", {
        type: "none",
      })
        .then(() => {
          API_goods.changeGoodsSale({
            ...this.getListParams(),
            checkedGoodsId: [],
            sold_separately: 1,
            // goods_source: this.getGoodsSource(),
          }, 1).then((res) => {
            this.$message.success("操作成功");
            this.checkWaitSold();
          });
        })
        .catch(() => { });
    },
    closeGoodsSale () {
      // let list = [...this.goodsIds]
      // if (list.length > 0) {
      //   API_goods.changeGoodsSale({
      //     checkedGoodsId: list,
      //     sold_separately: 0,
      //     //goods_source: this.getGoodsSource()
      //   }, 0).then((res) => {
      //     this.$message.success("操作成功");
      //     this.checkWaitSold();
      //   });
      //   return;
      // }
      this.$confirm(`确定要将当前全部商品的【单独售卖】开关关闭吗？`, "提示", {
        type: "none",
      })
        .then(() => {
          API_goods.changeGoodsSale({
            ...this.getListParams(),
            checkedGoodsId: [],
            sold_separately: 0,
            // goods_source: this.getGoodsSource(),
          }, 1).then((res) => {
            this.$message.success("操作成功");
            this.checkWaitSold();
          });
        })
        .catch(() => { });
    },
    changeGoodsSale (e, row) {
      API_goods.changeSaleStatus(row.goods_id, +e)
        .then((res) => {
          this.$message.success("操作成功");
          // this.GET_GoodsList()
        })
        .catch(() => {
          row.sold_separately_enable = e == 1 ? 0 : 1;
        });
    },
    changeGoodsExchange (e, row) {
      API_goods.editAiExchangeTh(row.id, +e)
        .then((res) => {
          this.$message.success("操作成功");
          // this.GET_GoodsList()
        })
        .catch(() => {
          row.goods_exchange = e == 1 ? 0 : 1;
        });
    },
    changeGiftTime (e) {
      if (!e || e.length < 2) {
        this.gift.create_time_start = "";
        this.gift.create_time_end = "";
      } else {
        this.gift.create_time_start = e[0].getTime() / 1000;
        this.gift.create_time_end = e[1].getTime() / 1000 + 86399;
      }
    },
    submitGiftRemark () {
      const { id, remark } = this.remarkValidateForm;
      API_gift.editGiftRemark(id, remark)
        .then((res) => {
          this.remarkDialog.visible = false;
          this.GET_GoodsList();
          this.$message.success("修改成功");
          if (remark) {
            this.history.remark = remark;
          }
        })
        .catch(() => { });
      return false;
    },
    showCopyGiftBox (row) {
      const { id, gift_name, web_gift_name } = row;
      this.copyValidateForm = {
        id,
        gift_name: this.generateNewName(gift_name),
        web_gift_name: this.generateNewName(web_gift_name),
      };
      this.copyDialog.display({
        title: "复制",
      });
      this.$nextTick(() => {
        this.$refs["copyValidateForm"].validate();
      });
    },
    submitCopyGift () {
      this.$refs["copyValidateForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        console.log(this.copyValidateForm);

        API_gift.copyCombo(this.copyValidateForm).then((res) => {
          this.copyDialog.visible = false;
          this.GET_GoodsList();
        });
      });
      return false;
    },
    openGiftGoods (row) {
      this.$confirm(`确定要启用该套餐礼包吗？`, "启用操作", { type: "warning" })
        .then(() => {
          API_gift.changeGiftGoodsStatus(row.id, 1)
            .then((res) => {
              this.GET_GoodsList();
              this.$message.success("启用成功");
            })
            .catch(() => { });
        })
        .catch(() => { });
    },
    closeGiftGoods (row) {
      this.$confirm(
        `确定要禁用这个套餐礼包吗？禁用后前台用户将无法进行兑换。`,
        "禁用操作",
        { type: "warning" }
      )
        .then(() => {
          API_gift.changeGiftGoodsStatus(row.id, 0)
            .then((res) => {
              this.GET_GoodsList();
              this.$message.success("禁用成功");
            })
            .catch(() => { });
        })
        .catch(() => { });
    },
    delGiftGoods (row) {
      this.$confirm(`确定要删除该商品吗？删除后无法还原该商品。`, "删除提示", {
        type: "warning",
      })
        .then(() => {
          API_gift.delGiftGoods(row.id)
            .then((res) => {
              this.GET_GoodsList();
              this.$message.success("删除成功");
            })
            .catch(() => { });
        })
        .catch(() => { });
    },
    editGiftRemark (row) {
      this.remarkValidateForm = {
        id: row.id,
        remark: row.remark || this.history.remark || "",
      };
      this.remarkDialog.display({
        title: "备注信息",
      });
    },
    isEmpty (value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value == "object" && Object.keys(value).length == 0) ||
        (typeof value == "string" && value.trim().length == 0)
      );
    },
    giftSearch () {
      // console.log(this.gift);
      this.GET_GoodsList();
    },
    getGiftGoodsList () {
      const { page_no, page_size } = this.params;
      const {
        labelNamesArea,
        comboType,
        low_price,
        high_price,
        lowDistributionProfit,
        highDistributionProfit,
        lowPriceProfit,
        highPriceProfit,
        lowMarketProfit,
        highMarketProfit,
        company,
        lowEnterPrice,
        highEnterPrice,
        key_word,
        keyword,
        create_time_start,
        create_time_end,
        goodsExchange,
        comboStatus,
        highMktPrice,
        lowMktPrice,
        sort,
        normalStatus
      } = this.gift;
      let err = [];
      if (
        !this.isEmpty(lowEnterPrice) &&
        !this.isEmpty(highEnterPrice) &&
        lowEnterPrice > highEnterPrice
      ) {
        err.push("最低分销总价应小于等于最高分销总价");
      }
      if (
        !this.isEmpty(lowMktPrice) &&
        !this.isEmpty(highMktPrice) &&
        lowMktPrice > highMktPrice
      ) {
        err.push("最低套餐价应小于等于最高套餐价");
      }
      if (err.length) {
        for (let i = 0; i < err.length; i++) {
          setTimeout(() => {
            this.$message.error(err[i]);
          }, 300 * i);
        }
        this.loading = false;
        return;
      }
      let params = {
        page_no,
        page_size,
        labelNamesArea,
        comboType,
        low_price,
        high_price,
        lowDistributionProfit,
        highDistributionProfit,
        lowPriceProfit,
        highPriceProfit,
        lowMarketProfit,
        highMarketProfit,
        company,
        lowEnterPrice,
        highEnterPrice,
        create_time_start,
        create_time_end,
        goodsExchange,
        comboStatus,
        highMktPrice,
        lowMktPrice,
        sort,
        normalStatus
      };
      params.categoryPath = this.giftCategoryParams;
      if (key_word) params[key_word] = keyword;
      API_gift.getGiftGoodsList({
        ...params,
      }).then(
        (response) => {
          if (this.params.page_no !== 1 && response.data.length === 0) {
            this.params.page_no -= 1;
            this.GET_GoodsList();
          }
          this.loading = false;
          this.pageData = response;
          this.pageData.data = this.pageData.data.map(item => {
            item.label_names_gift = item.label_names_gift && item.label_names_gift.replaceAll(',', '、')
            item.label_names_area = item.label_names_area && item.label_names_area.replaceAll(',', '、')
            return item
          })
          // 重新绘制表格
          // this.$refs.tableLayout.$refs.table.doLayout();
        },
        (err) => {
          this.loading = false;
        }
      );
    },
    toEditGiftGoods (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        name: "editGiftGoods",
        params: {
          id: row.id,
        },
      });
    },
    addGiftGodds () {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        name: "editGiftGoods",
      });
    },
    openGoodsExchange () {
      console.log(this.gift.multipleSelection);
      let list = this.gift.multipleSelection.map(item => item.id);
      if (list.length > 0) {
        API_goods.changeGoodsAiExchange({
          combo_ids: list,
          ai_exchange: 1,
        }, 0).then((res) => {
          this.$message.success("操作成功");
          this.checkWaitExchange();
        });
        return;
      }
      this.$confirm(`确定要将当前全部礼包的【智能换货】开关开启吗？`, "提示", {
        type: "none",
      })
        .then(() => {
          if (!this.getGiftListParams()) {
            return;
          }
          API_goods.changeGoodsAiExchange({
            ...this.getGiftListParams(),
            combo_ids: [],
            ai_exchange: 1,
          }, 1).then((res) => {
            this.$message.success("操作成功");
            this.checkWaitExchange();
          });
        })
        .catch(() => { });
    },
    closeGoodsExchange () {
      let list = this.gift.multipleSelection.map(item => item.id);
      if (list.length > 0) {
        API_goods.changeGoodsAiExchange({
          combo_ids: list,
          ai_exchange: 0,
        }, 0).then((res) => {
          this.$message.success("操作成功");
          this.checkWaitExchange();
        });
        return;
      }
      this.$confirm(`确定要将当前全部礼包的【智能换货】开关关闭吗？`, "提示", {
        type: "none",
      })
        .then(() => {
          if (!this.getGiftListParams()) {
            return;
          }
          API_goods.changeGoodsAiExchange({
            ...this.getGiftListParams(),
            combo_ids: [],
            ai_exchange: 0,
          }, 1).then((res) => {
            this.$message.success("操作成功");
            this.checkWaitExchange();
          });
        })
        .catch(() => { });
    },

    restrictCancel (i) {
      this.activeIndex = null;
      this.restrictNum = "";
    },
    changNum (e, i) {
      console.log(e);
      this.restrictNum = e.match(/^[1-9]\d*$/g);
      console.log(this.restrictNum);
      this.$forceUpdate();
    },
    restrictChang (i) {
      this.activeIndex = i;
      this.restrictNum =
        `${this.pageData.data[i].restrict_num}` === "-1"
          ? ""
          : this.pageData.data[i].restrict_num;
      console.log(this.restrictNum);
    },
    restrictBlur (i) {
      this.pageData.data[i].restrict_num = !this.restrictNum
        ? -1
        : this.restrictNum;
      const params = this.pageData.data[i];
      API_goods.shopGoodsRestrict(params.id, params.restrict_num).then(
        (res) => {
          this.activeIndex = null;
          this.restrictNum = "";
        }
      );
    },
    async getNavList () {
      const res = await API_Floor.getNav()
      this.navList = res.map((item) => {
        switch (item.navigation_id) {
          case 65:
            item.navigation_id = "2";
            break;
          case 63:
            item.navigation_id = "1";
            break;
          case 66:
            item.navigation_id = "0";
            break;
        }
        return item;
      });
      const shop = await getSellerShopExtAuth(this.$store.getters.shopInfo.shop_id)
      this.navList = [
        ...this.navList,
        {
          navigation_id: "3",
          navigation_name: "礼包列表",
        }
      ];
      if (shop.shop_self_goods_open !== 'OPEN') return
      this.navList.push({
        navigation_id: "7",
        navigation_name: "商城自有商品",
      })
    },
    // 标签切换
    handleClick (tab) {
      this.pageData.data = [];
      this.params = {
        has_goods: 0,
        page_no: 1,
        page_size: 20,
        self_goods: tab.name,
        sold_separately_enable: '',

      };
      this.key_word = "goods_name";
      this.$refs.tableLayout.$refs.table.clearSort();
      // 将重新绘制表格放到标签切换的时候
      this.$nextTick(() => {
        this.$refs.tableLayout.$refs.table.doLayout();
      });
      if (this.activeName == 3) this.gift.key_word = "gift_name";
      this.GET_GoodsList();
    },
    loadReviseTask () {
      // 判断是否有改价任务 OK没有任务可以改价 NO有任务不能改价
      this.reviseTaskTimer = setInterval(() => {
        API_goods.hasReviseTask().then((res) => {
          if (res === "OK") {
            this.hasReviseTask = false;
            clearInterval(this.reviseTaskTimer);
          } else {
            this.hasReviseTask = true;
          }
        });
      }, 1000);
    },
    handleInputChange () {
      if (this.revisePriceForm.ratio < 0) {
        this.revisePriceForm.ratio = 0;
        // revisePriceForm.ratio  symbol
      } else if (
        this.revisePriceForm.symbol === "DOWN" &&
        this.revisePriceForm.ratio >= 100
      ) {
        this.revisePriceForm.ratio = 100;
      }
    },
    uderShops () {
      this.$confirm("是否要下架全部无货商品?", "提示", {
        type: "warning",
      }).then(() => {
        API_goods.underShops().then((res) => {
          this.$message.success("下架成功");
          this.GET_GoodsList();
        });
      });
    },
    addGoods () {
      if (this.activeName == 7) {//跳转商城自建商品
        this.$store.dispatch("addVisitedViews", this.$route);
        console.log(this.$route);
        this.$router.push({
          // path: '/markets/publish',
          name: 'privateGoodsPublish',
          params: {
            // goodsid: this.formData.goods_id,
            isdraft: 1,
            callback: this.GET_GoodsList,
          }
        });
      } else {
        this.$router.push({
          path: "/shop-goods/market",
        });
      }
    },
    editGoods (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        // path: '/markets/publish',
        name: 'privateGoodsPublish',
        params: {
          goodsid: row.goods_id,
          isdraft: 1,
          callback: this.GET_GoodsList,
        }
      });
    },
    exportShop () {
      this.loading = true;
      const params = { ...this.params };
      // this.params.page_no = 1;
      // this.params.page_size = 99999;
      params.page_no = 1;
      params.page_size = 99999;
      API_goods.getShopGoodsList(params).then(
        (response) => {
          this.loading = false;
          // 商品来源，0普通商品，1京东商品，2苏宁商品
          const json = {
            sheet_name: "商品列表",
            sheet_values: response.data.map((item) => {
              let obj = {
                商品编号: item.good_sn,
                商品分类: item.cat_name,
                商品名称: item.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;'),
                匹配礼包数: item.match_gift_num || 0,
                商品来源: item.goods_source === 0 ? '平台方' : (item.goods_source === 1 ? '京东' : '自有'), //商品来源
              }
              if (this.MixinIsFormEnterprise) {
                obj['会员价'] = item.price
                obj["毛利率"] = item.enterprise_profit
                obj["利润率"] = (item.shop_profit || 0) + '%'
              }
              if (!(!this.MixinIsFormEnterprise && this.ispetroChina)) {
                obj['分销价'] = Foundation.formatPrice(item.enterprise_revise_price)
              }
              let newobj = {
                ...obj,

                市场价: Foundation.formatPrice(item.mktprice),
                销售价: Foundation.formatPrice(item.revise_price),
                实际库存:
                  item.goods_source === 1
                    ? item.quantity > 0
                      ? "有货"
                      : "无货"
                    : item.quantity,
                终身限购数: item.restrict_num === -1 ? "不限" : item.restrict_num,
                可售库存:
                  item.goods_source === 1
                    ? item.enable_quantity > 0
                      ? "有货"
                      : "无货"
                    : item.enable_quantity,
                创建时间: Foundation.unixToDate(item.create_time),
                总销售: item.buy_count,
                状态: item.shop_goods_status == 0 ? "已下架" : "已上架",
              }
              if (this.activeName == 7) {
                newobj['单独售卖'] = item.sold_separately_enable == 1 ? "是" : "否" //是否单独售卖
                newobj['是否到店支付'] = item.offline_store_pay == 2 ? "是" : "否"
                // 状态原因: item.shop_under_message ? item.shop_under_message : '',
              }
              return newobj
            }),
          };
          console.log(json);
          this.MixinExportJsonToExcel(json, "商品列表");
        },
        (err) => {
          this.loading = false;
        }
      );
    },
    clearRes () {
      this.params.self_goods = "";
      this.params.low_enterprice = "";
      this.params.high_enterprice = "";
      this.params.low_mktprice = "";
      this.params.high_mktprice = "";
      this.params.shop_goods_status = "";
      this.params.pre = "";
      this.params.sign = "";
      this.params.suf = "";
      this.params.has_goods = 0;
      this.params.page_no = 1;
      this.params.page_size = 10;
      this.params.goods_name = "";
      this.categoryParams = "";
      this.gift = {
        lowEnterPrice: undefined,
        highEnterPrice: undefined,
        highMktPrice: undefined,
        lowMktPrice: undefined,
        key_word: "gift_name",
        keyword: "",
        time_range: ["", ""],
        create_time_start: "",
        create_time_end: "",
        goodsExchange: "",
        comboStatus: "",
        multipleSelection: [],
      };
      this.GET_GoodsList();
    },
    changeInit () {
      this.GET_GoodsList();
    },
    sortChange () {
      this.GET_GoodsList();
    },
    revisePriceClosed () {
      this.revisePriceForm = {
        revise_type: "",
        revise_value: "",
      };
    },
    /** 保存商品选择器选择的商品 */
    refreshFunc (val) {
      let tableData = val;
      /** 去重 转化 */
      const res = new Map();
      tableData = tableData.filter(
        (key) => !res.has(key.goods_id) && res.set(key.goods_id, 1)
      );
      this.goodsIds = tableData.map((key) => {
        return key.goods_id;
      });

      this.goodsIds.forEach((item) => {
        API_goods.selectShopGoods({
          goods_id: item,
          shop_goods_status: 0,
          revise_type: 2,
          revise_value: 0,
        }).then(() => {
          this.$message.success("添加成功");
          this.GET_GoodsList();
        });
      });
    },
    close () {
      this.dialogShopShow1 = false;
    },
    res () {
      // this.dialogShopShow = true;
      this.dialogShopShow1 = false;
    },
    handleShopPickerConfirm1 (item) {
      this.dialogShopShow1 = false;
      this.pa.enterprise_price = item.enterprise_price;
      this.pa.shop_goods_status = item.shop_goods_status;
      this.pa.shop_price = item.shop_price;
      this.pa.change_price = 1;
      if (item.cop === "1") {
        this.pa.choose_type = 1;
        this.pa.goods_ids = this.getGoodsId();
      } else {
        this.pa.goods_ids = [];
        this.pa.choose_type = 2;
      }

      this.pa.goods_source = this.goods_source;

      this.pa.param = { ...this.params };

      if (this.batchModifyPrice) {
        this.pa.is_batch = 0;
      } else {
        this.pa.is_batch = 1;
      }
      API_goods.sendShop(this.pa).then((res) => {
        this.$message.success("改价任务已开启!");
        this.hasReviseTask = true;
        this.GET_GoodsList();
        this.loadReviseTask();
        this.checkWaitSold();
        this.checkWaitExchange();
      });
    },
    getGoodsId () {
      let arr = [];
      if (!this.MixinIsFormEnterprise) {
        for (let i = 0; i < this.idsList.length; i++) {
          arr.push(this.idsList[i]);
        }
      } else {
        for (let i = 0; i < this.goodsIds.length; i++) {
          arr.push(this.goodsIds[i]);
        }
      }
      return arr;
    },
    //批量设置自提时间
    settingTime () {
      if (this.idsList.length <= 0) {
        this.$message.error("请勾选商品");
        return;
      }
      this.settingTimesVisible = true;
    },
    handleBatchCommand (cmd) {
      switch (cmd) {
        case "modify":
          if (!this.MixinIsFormEnterprise) {
            return this.revisePriceShow(this.idsList, true);
          } else {
            return this.revisePriceShow(this.goodsIds, true);
          }
        case "on":
          return this.handleUpGoods();
        case "off":
          return this.handleUnderGoods();
        case "remove":
          return this.handleDeleteGoods();
        case "clear":
          return this.uderShops();
      }
    },

    /** 商品改价 */
    revisePriceShow (list, type) {
      if (!(list && list.length && list.length > 0)) {
        this.$message.error("请勾选商品");
        return;
      }

      this.batchModifyPrice = type;

      if (!this.MixinIsFormEnterprise) {
        // this.isRevisePrice = true;
        this.revisePriceList = list;
        this.modifyPriceDialogRef.display({
          title: type ? "批量改价" : "改价",
        });
        this.revisePriceTitle = type ? "批量改价" : "改价";
      } else {
        this.dialogShopShow1 = true;
        this.pa.shop_id = this.$store.state.user.shop.shop_id;
        this.shop_id = this.$store.state.user.shop.shop_id;
        this.idsList = list;
        this.goodsIds = list;
      }
    },

    /** 商品改价 */
    revisePrice () {
      if (!this.revisePriceList.length && this.cou === 1) {
        this.$message.error("请选择要改价的商品");
        return false;
      } else {
        let isd = this.revisePriceList;

        const params = {
          ...this.revisePriceForm,
        };

        if (this.revisePriceTitle === "改价" || this.cou === 1) {
          params.choose_type = 1;
          params.ids = isd;
        } else {
          params.choose_type = 2;
          params.ids = [];
          params.param = {
            ...this.params,
          };
        }

        if (this.revisePriceTitle === "改价") {
          params.is_batch = 1;
        } else {
          params.is_batch = 0;
        }

        return new Promise((resolve) => {
          this.$confirm("确认修改此价格?", "提示", { type: "warning" }).then(
            () => {
              API_goods.nrevisePrice(params).then(() => {
                this.$message.success("改价成功");
                // this.isRevisePrice = false;
                this.modifyPriceDialogRef.dismiss();
                this.GET_GoodsList().then(
                  () => {
                    this.loadReviseTask();
                    resolve(true);
                  },
                  () => {
                    resolve(false);
                  }
                );
              });
            },
            () => resolve(false)
          );
        });
      }
    },

    /** 库存边界限制 */
    checkQuantity (value) {
      if (!value && value !== 0) {
        this.$message.error("库存不能为空");
      } else if (!RegExp.integer.test(value) && parseInt(value) !== 0) {
        this.$message.error("请输入整数");
      } else if (!(parseInt(value) >= 0 && parseInt(value) <= 99999999)) {
        this.$message.error("请输入0 - 99999999之间的正整数");
      }
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsList();
    },

    search (name) {
      delete this.params.skip;
      sessionStorage.removeItem("shopGoodsListType");
      // delete this.$route.query.type
      this.$route.query.type = 0;
      this.params.category_path = this.categoryParams;
      delete this.params.goods_name;
      delete this.params.goods_sn;

      this.params[this.key_word] = this.keyword;
      if (this.params.self_goods === "2") {
        // this.params["goods_source"] = 1;
      } else {
        this.params["goods_source"] = 0;
      }
      this.params.page_no = 1;
      this.GET_GoodsList();
    },

    /** 切换上下架状态*/
    changeGoodsStatus (val) {
      // delete this.market_enable;
      if (val !== "" && val !== -1) {
        this.params = {
          ...this.params,
          shop_goods_status: val,
        };
      }
      this.GET_GoodsList();
    },

    /** 下架*/
    handleUnderGoods (row) {
      let id = "";
      let arr = [];
      if (row) {
        id = row.id;
        arr.push(row.id);
      }
      if (!row) {
        if (this.idsList.length <= 0) {
          this.$message.error("请勾选商品");
          return;
        }
        id = this.idsList;
        arr = this.idsList;
      }
      this.$prompt("确认下架此商品，输入下架原因", "提示", {
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
      }).then(({ value }) => {
        API_goods.changeStatus(id, {
          ids: arr,
          status: 0,
          reason: value,
        }).then(() => {
          this.$message.success("下架成功");
          this.GET_GoodsList();
        });
      });
    },

    /** 上架*/
    handleUpGoods (row) {
      let id = "";
      let arr = [];
      if (row) {
        id = row.id;
        arr.push(row.id);
      }
      if (!row) {
        if (this.idsList.length <= 0) {
          this.$message.error("请勾选商品");
          return;
        }
        id = this.idsList;
        arr = this.idsList;
      }
      this.$confirm("确认上架此商品, 是否继续?", "提示", {
        type: "warning",
      }).then(() => {
        API_goods.changeStatus(id, {
          ids: arr,
          status: 1,
        }).then(() => {
          this.$message.success("上架成功");
          this.GET_GoodsList();
        });
      });
    },

    /** 切换商品类型 */
    changeGoodsType (val) {
      delete this.params.goods_type;
      if (val !== "") {
        this.params = {
          ...this.params,
          goods_type: val,
        };
      }
      this.GET_GoodsList();
    },

    /** 切换分组*/
    changeGoodsCateGory (data) {
      delete this.params.shop_cat_path;
      if (data && Array.isArray(data) && data.length !== 0) {
        this.params = {
          ...this.params,
          shop_cat_path: "0|" + data.join("|") + "|",
        };
      }
      this.GET_GoodsList();
    },

    /** 显示下架原因 */
    showUnderReason (row) {
      this.isShowUnderReason = false;
      this.isShowUnderReason = true;
      this.under_reason = row.shop_under_message;
    },

    GET_GoodsList () {
      if (+this.activeName == 3) {
        this.loading = true;
        return this.getGiftGoodsList();
      }
      if (this.activeName === "2" && this.areaSelectData.province !== "") {
        let jd_area = "" + this.areaSelectData.province + "_";
        if (this.areaSelectData.city !== "")
          jd_area += this.areaSelectData.city + "_";
        else jd_area += "0_";
        if (this.areaSelectData.county !== "")
          jd_area += this.areaSelectData.county + "_";
        else jd_area += "0_";
        if (this.areaSelectData.town !== "")
          jd_area += this.areaSelectData.town;
        else jd_area += "0";
        this.params.jd_area = jd_area;
        this.params.jd_area_state = this.areaSelectData.jd_area_state;
        this.quantityStatus = true;
      } else if (this.params.jd_area) {
        delete this.params.jd_area;
        delete this.params.jd_area_state;
        this.quantityStatus = false;
      } else this.quantityStatus = false;
      this.params.is_combo = 0;
      this.params.supplier_enable = 1;
      this.loading = true;
      return API_goods.getShopGoodsList(this.params).then(
        (response) => {
          if (this.params.page_no !== 1 && response.data.length === 0) {
            this.params.page_no -= 1;
            this.GET_GoodsList();
          }
          this.loading = false;
          this.pageData = response;
          // 重新绘制表格
          // this.$refs.tableLayout.$refs.table.doLayout();
        },
        (err) => {
          this.loading = false;
        }
      );
    },

    /** 发布商品*/
    publishGoods () {
      this.showDialog = true;
      // this.$router.push({ name: 'goodPublish', params: { callback: this.GET_GoodsList }})
    },

    /** 跳转回收站*/
    gotoRecycle () {
      this.$router.push({ path: "/goods/recycle-station" });
    },

    /** 编辑商品 isdraft 商品列表1*/
    handleEditGoods (row) {
      this.$router.push({
        name: "shopGoodPublish",
        params: {
          goodsid: row.goods_id,
          isdraft: 1,
          callback: this.GET_GoodsList,
        },
      });
    },

    /** 删除商品 */
    handleDeleteGoods (row) {
      let arr = [];
      let id = "";
      if (row) {
        id = row.id;
        arr.push(row.id);
      }
      if (!row) {
        if (this.idsList.length <= 0) {
          this.$message.error("请勾选商品");
          return;
        }
        id = this.idsList;
        arr = this.idsList;
      }
      this.$confirm("确认删除此商品, 是否继续?", "提示", {
        type: "warning",
      }).then(() => {
        API_goods.deleteGoodsa(arr).then(() => {
          this.GET_GoodsList();
          this.$message.success("删除商品成功！");
        });
      });
    },

    /** 合并数据相同的单元格 */
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex < this.goodsStockTitle.length - 3) {
        const _row = this.concactArray[rowIndex][columnIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },

    /** 计算要合并列的位置 */
    concactArrayCom (index, item) {
      let _isMerge = false;
      /** 循环列 先循环第一列 若相同则合并 再循环第二列 依次循环 若不相同 则不合并并终止此列循环开始下一列循环 */
      let _currnetRow = [];
      for (let i = 0, _len = this.goodsStockTitle.length - 3; i < _len; i++) {
        if (this.goodsStockTitle[i].prop === "sku_id") {
          i++;
          continue;
        }
        if (
          index > 0 &&
          item[this.goodsStockTitle[i].prop] !==
          this.goodsStockData[index - 1][this.goodsStockTitle[i].prop]
        ) {
          _currnetRow[i] = 1;
          _isMerge = true;
        } else if (index > 0 && !_isMerge) {
          _currnetRow[i] = 0;
          let _count = 1;
          while (this.concactArray[index - _count][i] === 0) {
            _count++;
          }
          this.concactArray[index - _count][i] += 1;
        } else {
          // index === 0
          _currnetRow[i] = 1;
        }
      }
      this.concactArray.push(_currnetRow);
    },

    /** 库存 */
    handleStockGoods (row) {
      this.goodsId = row.goods_id;
      this.goodsStockshow = true;
      setTimeout(() => {
        this.$refs["goodsStockData"].resetFields();
      });
      API_goods.getGoodsStockList(row.goods_id, {}).then((response) => {
        this.goodsStockTitle = this.goodsStockData = [];
        this.goodsStocknums = response.length;
        // 构造待发货字段
        if (response.length > 1) {
          this.$nextTick(() => {
            response.forEach((key) => {
              // 构造待发货字段
              this.$set(
                key,
                "deliver_goods_quantity",
                parseInt(key.quantity) - parseInt(key.enable_quantity)
              );
              // 构造表头
              let _skus = key.spec_list.map((elem) => {
                return {
                  label: elem.spec_name,
                  prop: elem.spec_name,
                };
              });
              this.goodsStockTitle = _skus.concat([
                {
                  label: "规格id",
                  prop: "sku_id",
                },
                {
                  label: "库存",
                  prop: "quantity",
                },
                {
                  label: "待发货数",
                  prop: "deliver_goods_quantity",
                },
              ]);
              // 构造表结构
              let _skuData = key.spec_list.map((elem) => {
                let _map = new Map().set(elem.spec_name, elem.spec_value);
                let obj = Object.create(null);
                for (let [k, v] of _map) {
                  obj[k] = v;
                }
                return obj;
              });
              const _key = {
                sku_id: key.sku_id,
                quantity: key.quantity,
                deliver_goods_quantity: key.deliver_goods_quantity,
              };
              this.goodsStockData.push(Object.assign(_key, ..._skuData));
            });
            // 计算表格合并的位置
            this.concactArray = [];
            this.goodsStockData.forEach((key, index) => {
              this.concactArrayCom(index, key);
            });
          });
        } else {
          response.forEach((key) => {
            // 构造待发货字段
            this.$set(
              key,
              "deliver_goods_quantity",
              parseInt(key.quantity) - parseInt(key.enable_quantity)
            );
          });
          this.goodsStockData = response[0];
        }
      });
    },

    /** 保存库存商品 */
    reserveStockGoods () {
      let _params = [];
      if (Array.isArray(this.goodsStockData)) {
        _params = this.goodsStockData.map((elem) => {
          return {
            quantity_count: elem.quantity,
            sku_id: elem.sku_id,
          };
        });
      } else {
        _params.push({
          quantity_count: this.goodsStockData.quantity,
          sku_id: this.goodsStockData.sku_id,
        });
      }
      const _res = _params.some((key) => {
        return (
          !(
            parseInt(key.quantity_count) >= 0 &&
            parseInt(key.quantity_count) < 99999999
          ) || !/^[0-9]\d*$/.test(key.quantity_count)
        );
      });
      if (_res) {
        this.$message.error("库存须为0 - 99999999之间的整数");
        return;
      }
      API_goods.reserveStockGoods(this.goodsId, _params).then(() => {
        this.goodsStockshow = false;
        this.$message.success("库存商品保存成功");
        this.GET_GoodsList();
      });
    },

    /** 获取分销设置 */
    getDistributionSet () {
      API_goods.getDistributionSet().then((response) => {
        this.distributionSet = response.message;
      });
    },

    /** 返利 获取返利信息*/
    handleRebate (row) {
      setTimeout(() => {
        this.$refs["disRebateData"].resetFields();
      });
      API_goods.getDistributionInfo(row.goods_id).then((response) => {
        this.isShowDisRebate = true;
        this.disRebateData = {
          /** 商品id */
          goods_id: response.goods_id || row.goods_id,

          /** 1级返利 */
          grade1Rebate: response.grade1_rebate,

          /** 2级返利 */
          grade2Rebate: response.grade2_rebate,
        };
      });
    },

    /** 保存分销返利信息 */
    reserveDisSet (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          API_goods.setDistributionInfo(this.disRebateData).then(() => {
            this.isShowDisRebate = false;
            this.$message.success("当前商品分销返利金额修改成功");
          });
        }
      });
    },
    handleSelectionChange (val) {
      if (this.activeName == 3) {
        this.gift.multipleSelection = val;
        return;
      }
      let ids = [];
      let goodsIds = [];
      val.forEach((item) => {
        goodsIds.push(item.goods_id);
      });
      val.forEach((item) => {
        ids.push(item.id);
      });

      this.idsList = ids;
      this.goodsIds = goodsIds;
    },
    handleSortChange ({ prop, order }) {
      if (this.activeName == 3) {
        if (order) {
          this.gift.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"}`;
        } else {
          this.gift.sort = "";
        }
      } else {
        if (order) {
          this.params.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"
            }`;
        } else {
          this.params.sort = "";
        }
      }
      this.GET_GoodsList();
    },
    /**
     * 获取区域数据
     * @param type
     * @param data
     */
    getAreaHandle (type, data = {}) {
      if (type === "province") {
        API_goods.getJDProvince().then((res) => {
          this.provinceList = this.dataHandle(res.data.result);
        });
      } else if (type === "city") {
        API_goods.getJDCity(data.id).then((res) => {
          this.cityList = this.dataHandle(res.data.result);
        });
      } else if (type === "county") {
        API_goods.getJDCounty(data.id).then((res) => {
          this.countyList = this.dataHandle(res.data.result);
        });
      } else if (type === "town") {
        API_goods.getJDTown(data.id).then((res) => {
          this.townList = this.dataHandle(res.data.result);
        });
      }
    },
    dataHandle (data) {
      let returnData = [];
      const keys = Object.keys(data);
      const values = Object.values(data);
      keys.forEach((key, index) => {
        const data = {
          name: key,
          id: values[index],
        };
        returnData.push(data);
      });
      return returnData;
    },
    /**打开导入商品弹窗 */
    importShopGoods () {
      console.log(this.$refs.importGoods)
      this.$refs.importGoods.show();
    },
    /**下载导入商品失败数据 */
    downloadError ({ sum, ok, no, errorExport }) {
      let msg = `本次共导入${sum}条数据，其中导入成功${ok}条，失败${no}条。是否要下载导入失败的数据表？`
      this.$confirm(msg, '提示', {
        type: 'warning'
      }).then(() => {
        this.handleExportSelectedList(errorExport)
      });
    },
    // 导出勾选商品
    handleExportSelectedList (failGoodsData) {
      let res = failGoodsData
      let sheet_values = []
      sheet_values = res.map((item, idx) => ({
        商品名称: item.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;'),
        一级商品分类: item.first_name,
        二级商品分类: item.second_name,
        三级商品分类: item.three_name,
        市场价: Foundation.formatPrice(item.mktprice),
        销售价: Foundation.formatPrice(item.revise_price),
        成本价: Foundation.formatPrice(item.cost),
        总库存: item.quantity + '件',
        是否线下门店支付: item.is_store_pay == 2 ? '是' : '否',
        失败原因: item.remark
      }))
      const json = {
        sheet_name: '商品列表',
        sheet_values
      };
      this.MixinExportJsonToExcel(json, '商品列表');
    },
    /**更改礼包前端名称 */
    async changeWebName (row) {
      row.showWebNameInput = false
      row.web_gift_name = row.web_gift_name.trim()
      let { id: combo_id, web_gift_name: combo_name } = row
      if (combo_name == '') {
        combo_name = row.gift_name
      }
      try {
        const res = await API_gift.editCombEditName({ combo_id, combo_name })
        row.web_gift_name = combo_name
        this.$message.success('修改成功')
      } catch (error) {
        this.$message.warning(error.message)
      }
    }
  },
};
</script>
<style type="text/scss" lang="scss" scoped>
@import "@/components/GoodsClassify/styles";
</style>
<style lang="scss" scoped>
/deep/.goodsclassify {
  .el-dialog__body {
    padding: 0 !important;
  }
}

.icon-btn {
  border: 1px solid #5463a2;
  padding: 0;
  margin-left: 0;
  box-sizing: border-box;

  .el-icon-circle-plus-outline,
  .el-icon-remove-outline {
    font-size: 22px;
  }
}

.toolbar-search {
  margin-right: 10px;
  width: 20%;
}

.el-row {
  width: 100%;
}

/*下架原因*/
.under-reason {
  color: red;
  cursor: pointer;
}

/deep/ {
  .pop-sku {
    .toolbar {
      display: none;
    }

    .el-dialog__body {
      .el-table {
        border: 1px solid #e5e5e5;
      }
    }

    .el-table__body-wrapper {
      max-height: 400px;
      overflow-y: scroll;
    }
  }
}

.remark-warper {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.gift-remark {
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  position: relative;
  line-height: 1.5em;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 0;
    height: 1px;
    bottom: 0;
    background-color: #1a43a9;
    transition: 0.36s width;
  }

  &:hover {
    color: #1a43a9;

    &::after {
      width: 100%;
    }
  }
}

.no-remark {
  display: inline-block;
  text-align: center;
  font-size: 12px;
  color: #999;
}

.kind-num {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
  color: #1a43a9;
  text-decoration: underline;

  &:active {
    transform: scale(1.2);
  }
}

.kind-img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}

.key-cover {
  width: 200px;
  height: auto;
}

.kind-list {
  width: 100%;
  height: 500px;
}

.exportPPTForm {
  .el-radio {
    margin-top: 7px
  }

  /deep/ .site-logo {
    .deleteImg {
      position: absolute;
      right: -6px;
      top: -5px;
    }

    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      // overflow: hidden;

      &:hover {
        border-color: #409eff;
      }
    }
  }

  .site-logo-img {
    width: 120px;
    height: 120px;
    display: block;
  }

  .logo-uploader-icon {
    font-size: 20px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }
}
</style>
